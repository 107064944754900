import { useContext } from 'react'
import { UserContext } from '../context/user/UserContext'

export const useUserId = () => {
  const { user } = useContext(UserContext).state
  return user?.id
}

export const useSessionId = () => {
  const { sessionId } = useContext(UserContext).state
  return sessionId
}
