import { BroadcastEvent, IBroadcastEvent } from '../../interface/org'
import AddDeliveryPage from '../../components/delivery/AddDeliveryPage'
import useBroadcastChanel from '../../hooks/useChannel'
import { useSessionId } from 'src/hooks/useUserId'
import { useBoard } from 'src/hooks/useBoard'
import { openModal } from 'src/miro'
import Spinner from 'src/components/input/Spinner'

const TimeLinePage = () => {
  const { query, clearTimeline, reloadTimeline } = useBoard()
  const { data: timeline, isLoading } = query
  const sessionId = useSessionId()

  const broadcast = (e: IBroadcastEvent) => {
    if (e.event === BroadcastEvent.FRAME_DELETE) {
      clearTimeline()
    }
    if (e.event === BroadcastEvent.FRAME_CREATE) {
      reloadTimeline()
    }
  }

  useBroadcastChanel<IBroadcastEvent>('item:update', broadcast)

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <>
        {isLoading ? (
          <Spinner />
        ) : timeline ? (
          <AddDeliveryPage timeline={timeline} />
        ) : (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <button
              className="button button-primary"
              onClick={() =>
                openModal(`/newTimeline?parentSessionId=${sessionId}`)
              }
            >
              Create timeline
            </button>
          </div>
        )}
      </>
    </div>
  )
}

export default TimeLinePage
