import { ITemplate, ITimelineData, TaskCtrlAppCard } from '../interface/org'
import BaseService from './BaseService'
import { YearWeek } from '../util/time'
import { getMiroBoardInfo } from '../util/info'
import { AppCard, Frame } from '@mirohq/websdk-types'
import { Optional } from 'utility-types'

export const miroToTaskCtrlCard = (card: AppCard): TaskCtrlAppCard => {
  return {
    type: 'delivery',
    id: card.id,
    title: card.title,
    description: card.description,
    createdBy: card.createdBy,
    position: { x: card.x, y: card.y },
    synced: false,
  }
}

class TableKeeperService extends BaseService {
  private boardId = ''
  constructor() {
    getMiroBoardInfo().then((info) => {
      this.boardId = info?.id ?? ''
    })
    super(false)
  }

  private url = (url: string, params?: string[]) => {
    return `board/${this.boardId}/${url}/${params ? params.join('/') : ''}`
  }

  public saveTimeline = async (timeline: ITimelineData) => {
    const url = this.url('timeline')
    await this.post(url, { ...timeline, id: timeline.frameId })
  }

  public getTimeline = async (): Promise<ITimelineData | null> => {
    const url = this.url('timeline')
    try {
      this.init()
      const data = await this.get<ITimelineData[] | undefined>(url)
      if (!data || !data.length) return null
      const timeline = data[0]
      timeline.cells = timeline.cells.map((cell) => {
        cell.weekNumber = YearWeek.clone(cell.weekNumber)
        return cell
      })
      return timeline
    } catch (error) {
      return null
    }
  }

  public saveAppCard = async (
    appCard: Optional<TaskCtrlAppCard, 'id' | 'createdBy'>,
  ) => {
    const url = this.url('appCards')
    const data = await this.post(url, appCard)
    return data
  }

  public getAppCard = async (id: string) => {
    const url = this.url('appCards')
    const data = await this.get<TaskCtrlAppCard[]>(url)
    // TODO FIXME
    return data.find((card) => card.id === id)
  }

  // This endpoint can be used for both bulk creating and saving
  public bulkSaveAppCard = async (cards: TaskCtrlAppCard[]) => {
    const url = this.url('appCards', ['bulk_create'])
    const data = await this.post(url, cards)
    return data
  }

  public getAppCards = async () => {
    const url = this.url('appCards')
    const data = await this.get<TaskCtrlAppCard[]>(url)
    return data
  }

  public updateAppCard = async (appCard: TaskCtrlAppCard) => {
    const url = this.url('appCards')
    const data = await this.post(url, appCard)
    return data
  }

  public updateAppCardPosition = async (
    card: AppCard,
    pos: { x: number; y: number },
    timelineFrame?: Frame,
  ) => {
    if (card.parentId && timelineFrame) {
      const [parent] = await miro.board.get({
        type: 'frame',
        id: card.parentId,
      })
      try {
        await parent.remove(card)
      } catch (e) {
        console.error(e)
      }
    }
    card.x = pos.x + (timelineFrame?.x ?? 0)
    card.y = pos.y + (timelineFrame?.y ?? 0)
    await card.sync()
    await timelineFrame?.add(card)
  }

  public deleteAppCard = async (id: string) => {
    const url = this.url('appCards', [id])
    const data = await this.delete(url)
    return data
  }

  public deleteTimeline = async (id: string) => {
    const url = this.url('timeline', [id])
    const data = await this.delete(url)
    return data
  }

  public saveTemplate = async (projectId: number, rowTemplate: ITemplate) => {
    return this.post(`projects/${projectId}/template`, rowTemplate)
  }

  public getTemplates = async (projectId: number): Promise<ITemplate[]> => {
    return this.get<ITemplate[]>(`projects/${projectId}/template`)
  }
}

export default new TableKeeperService()
