import { useCallback, useEffect, useRef } from 'react'

const channels: Record<string, BroadcastChannel> = {}

const useBroadcastChannel = <T>(key: string, fn?: (d: T) => void) => {
  const itemUpdated = useCallback(
    (e: T) => {
      fn?.(e)
    },
    [fn],
  )
  const init = () => {
    if (!(key in channels)) {
      channels[key] = new BroadcastChannel(key)
    }
    return channels[key]
  }

  const channelRef = useRef<BroadcastChannel>(init())

  useEffect(() => {
    const channel = channelRef.current
    const messageHandler = (e: MessageEvent<T>) => {
      itemUpdated(e.data)
    }

    channel.addEventListener('message', messageHandler)

    return () => {
      channel.removeEventListener('message', messageHandler)
    }
  }, [channelRef, itemUpdated, key])

  return channelRef.current
}

export default useBroadcastChannel
