import { useCallback, useMemo, useState } from 'react'
import DeliveryForm from './AddDeliveryForm'
import { AppCard } from '@mirohq/websdk-types'
import {
  BroadcastEvent,
  ITimelineData,
  TaskCtrlAppCard,
} from '../../interface/org'
import useBroadcastChanel from '../../hooks/useChannel'
import AddKeypointPage from '../keypoint/AddKeypointPage'
import { Badge } from 'antd'
import CreateTimelineTemplate from 'src/pages/timeline/CreateTimelineTemplate'
import ItemSyncErrors from '../ItemSyncErrors'
import { openModal } from 'src/miro'
import { useAppCards } from 'src/query/tableKeeper'
import { useQueryClient } from '@tanstack/react-query'
import { isSynced } from 'src/models/TaskCtrlItem'
import ItemsForSync from '../ItemsForSync'
import dayjs from 'dayjs'
import { useSessionId } from '../../hooks/useUserId'
import { isInPlanningPeriod, itemSyncErrors } from '../../util/updateCard'

interface BC {
  event: BroadcastEvent
}

const AddDeliveryPage = ({ timeline }: { timeline: ITimelineData }) => {
  const sessionId = useSessionId()
  const queryClient = useQueryClient()

  const [showForm, setShowForm] = useState(false)
  const [showKeypointFrom, setShowKeypointFrom] = useState(false)

  const { data: appCardsData } = useAppCards()
  const appCards = useMemo(() => appCardsData ?? [], [appCardsData])

  useBroadcastChanel<BC>('item:update', () =>
    queryClient.invalidateQueries({ queryKey: ['appCards'] }),
  )

  const onSubmit = async (c: TaskCtrlAppCard, miroCard: AppCard) => {
    queryClient.setQueryData<TaskCtrlAppCard[]>(['appCards'], (data) => [
      ...(data ?? []),
      c,
    ])
    setShowForm(false)
    setShowKeypointFrom(false)
    miro.board.viewport.zoomTo(miroCard)
  }

  const syncErrors = useMemo(() => {
    return appCards
      .filter((d) => !isSynced(d))
      .map((a) => ({ card: a, errors: itemSyncErrors(a, timeline) }))
      .filter((item) => item.errors.length > 0)
  }, [appCards, timeline])

  const existingErrors = useMemo(() => {
    return appCards
      .filter(
        (d) => isSynced(d) && isInPlanningPeriod(dayjs(d.deadline), timeline),
      )
      .map((a) => ({ card: a, errors: itemSyncErrors(a, timeline) }))
      .filter((item) => item.errors.length > 0)
  }, [appCards, timeline])

  const newItems = useMemo(() => {
    return appCards.filter(
      (c) =>
        !isSynced(c) &&
        !c.taskCtrlId &&
        itemSyncErrors(c, timeline).length === 0,
    )
  }, [appCards, timeline])

  const updatedItems = useMemo(() => {
    return appCards.filter(
      (c) =>
        !isSynced(c) &&
        c.taskCtrlId &&
        itemSyncErrors(c, timeline).length === 0,
    )
  }, [appCards, timeline])

  const syncDisabled = useMemo(() => syncErrors.length !== 0, [syncErrors])

  const sync = useCallback(async () => {
    if (syncDisabled) return
    miro.board.ui.openModal({
      url: '/sync',
    })
  }, [syncDisabled])

  const noForm = useMemo(() => {
    return !showForm && !showKeypointFrom
  }, [showForm, showKeypointFrom])

  return (
    <div className="flex flex-col gap-4">
      {!noForm ? (
        showForm ? (
          <DeliveryForm onCancel={() => setShowForm(false)} onSave={onSubmit} />
        ) : (
          <AddKeypointPage
            onCancel={() => setShowKeypointFrom(false)}
            onSave={onSubmit}
          />
        )
      ) : (
        <div className="flex flex-col my-4 gap-4">
          <div className="flex">
            <CreateTimelineTemplate appCards={appCards} timeline={timeline} />
            <button
              className="button button-secondary w-1/2"
              onClick={async () =>
                await openModal(`/editTimeline?parentSessionId=${sessionId}`)
              }
            >
              Edit timeline
            </button>
          </div>
          <div className="flex gap-1 flex-col w-full pb-8">
            <div className="p-small text-gray-500">New cards</div>
            <div className="flex">
              <button
                onClick={() => setShowForm(true)}
                className={'button button-secondary w-1/2'}
              >
                Add delivery
              </button>
              <button
                onClick={() => setShowKeypointFrom(true)}
                className={'button button-secondary w-1/2'}
              >
                Add keypoint
              </button>
            </div>
          </div>

          <div className="w-full gap-1 flex flex-col items-center">
            <div className="p-small text-gray-500 pb-1">
              Sync timeline to TaskCtrl
            </div>
            <Badge count={updatedItems.length + newItems.length}>
              <button
                onClick={sync}
                disabled={syncDisabled}
                className={'button button-primary !mr-0'}
              >
                Synchronize
              </button>
            </Badge>
            <ItemsForSync cards={updatedItems} updated />
            <ItemsForSync cards={newItems} />
            <ItemSyncErrors errors={syncErrors} syncErrors />
            <ItemSyncErrors errors={existingErrors} />
          </div>
        </div>
      )}
    </div>
  )
}

export default AddDeliveryPage
