import { Input } from 'antd'
import { useState, useMemo } from 'react'
import {
  IOptionLabel,
  ITimelineData,
  TaskCtrlAppCard,
} from '../../interface/org'
import Select from '../../components/input/Select'
import {
  useDisciplineUsers,
  useMainProcesses,
  useMilestones,
  useUsers,
} from 'src/query/taskCtrl'
import { useUpdateAppCard } from 'src/query/tableKeeper'
import { isSynced } from '../../models/TaskCtrlItem'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { formatDate } from '../../util/time'
import dayjs from 'dayjs'
import {
  isInPlanningPeriod,
  onKeyPointUpdate,
  syncedWithTaskCtrlVersion,
} from '../../util/updateCard'

const { TextArea } = Input

const KeypointDetailPage = ({
  card,
  onUpdate,
  timeline,
}: {
  card: TaskCtrlAppCard
  onUpdate: () => void
  timeline: ITimelineData
}) => {
  const synced =
    isSynced(card) && !isInPlanningPeriod(dayjs(card.deadline), timeline)

  const [selectedUser, setSelectedUser] = useState(card.user)
  const [selectedResponsible, setSelectedResponsible] = useState(
    card.responsible,
  )
  const [selectedMainProcess, setSelectedMainProcess] = useState(
    card.mainProcess,
  )
  const [selectedMilestone, setSelectedMilestone] = useState(card.mile_stone)

  const [title, setTitle] = useState(card.title)
  const [description, setDescription] = useState(card.description)

  const { data: disciplineUsers } = useDisciplineUsers({
    discipline: card.discipline?.id,
  })

  const { data: users } = useUsers()
  const { data: mainProcesses } = useMainProcesses()
  const { data: milestones } = useMilestones()

  const updateKeypoint = useUpdateAppCard(card.id)

  const options = useMemo(() => {
    return [
      {
        options: milestones?.map((k) => ({
          value: k.id,
          label: k.name,
          deadline: k.deadline,
        })),
      },
    ]
  }, [milestones])

  const disableButton = useMemo(() => {
    return title === undefined || synced
  }, [title, synced])

  const updateTitle = (_title: string) => {
    setTitle(_title)
  }

  const updateDescription = (desc: string) => {
    setDescription(desc)
  }

  const updateResponsible = (id: number, op: IOptionLabel) => {
    setSelectedResponsible({ id, label: op.label })
  }

  const setReporter = (id: number, op: IOptionLabel) => {
    setSelectedUser({ id, label: op.label })
  }

  const setProcess = (id: number, op: IOptionLabel) => {
    setSelectedMainProcess({ id, label: op.label })
  }

  const setMilestone = (id: number, op: IOptionLabel) => {
    if (id) {
      setSelectedMilestone({ id, label: op.label, deadline: op.deadline })
    } else {
      setSelectedMilestone(undefined)
    }
  }

  const onSave = async () => {
    const updatedCard = {
      ...card,
      title,
      description,
      responsible: selectedResponsible,
      user: selectedUser,
      mile_stone: selectedMilestone,
      mainProcess: selectedMainProcess,
    }

    await updateKeypoint.mutateAsync({
      ...updatedCard,
      synced: syncedWithTaskCtrlVersion(
        updatedCard,
        updatedCard.taskCtrlVersion,
      ),
    })
    onUpdate()
    await onKeyPointUpdate(updatedCard)
    await miro.board.ui.closeModal()
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="">Title*</div>
        <Input
          placeholder="Add title"
          value={title}
          onChange={(e) => updateTitle(e.target.value)}
          disabled={synced}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="">Description</div>
        <TextArea
          rows={4}
          placeholder="Add description"
          value={description}
          onChange={(e) => updateDescription(e.target.value)}
          disabled={synced}
        />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col gap-2 w-1/2">
          <div className="">Deadline*</div>
          <Input
            placeholder="Deadline"
            value={formatDate(card.deadline)}
            disabled
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <div className="">Discipline*</div>
          <Input
            placeholder="Discipline"
            value={card.discipline?.id}
            disabled
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="w-1/2">
          <Select
            allowClear
            onChange={setMilestone}
            name={['name']}
            value={selectedMilestone}
            label={'Milestone'}
            groupOption={options}
            items={milestones ?? []}
            id={'id'}
            disabled={synced}
          />
        </div>
        <div className="w-1/2">
          <Select
            onChange={setProcess}
            name={['name']}
            value={selectedMainProcess?.id}
            label={'Main process*'}
            items={mainProcesses ?? []}
            id={'id'}
            disabled={synced}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="w-1/2">
          <Select
            onChange={updateResponsible}
            name={['firstName', 'lastName']}
            value={selectedResponsible}
            label={'Responsible*'}
            items={disciplineUsers ?? []}
            disabled={!card.discipline || synced}
            placeholder={
              !card.discipline
                ? 'Select a discipline to show users'
                : 'Select responsible'
            }
            error={!selectedResponsible || selectedResponsible.id < 0}
            id={'id'}
          />
        </div>
        <div className="w-1/2">
          <Select
            onChange={setReporter}
            name={['firstName', 'lastName']}
            value={selectedUser}
            label={'Reporter*'}
            items={users ?? []}
            error={!selectedUser || selectedUser.id < 0}
            id={'id'}
            disabled={synced}
          />
        </div>
      </div>

      <div className="flex mt-2 gap-3 items-center justify-center">
        {synced ? (
          <div>
            <div className={'flex items-center justify-center gap-2'}>
              <CheckCircleTwoTone
                style={{ fontSize: 'x-large' }}
                twoToneColor={'#43bc4f'}
              />
              <p> Already synced </p>
            </div>
            <p> Not possible to update items in the past period</p>
          </div>
        ) : (
          <button
            onClick={onSave}
            disabled={disableButton}
            className={'button  sticky bottom-0 button-primary '}
          >
            Update keypoint
          </button>
        )}
      </div>
    </div>
  )
}

export default KeypointDetailPage
