import { Checkbox } from 'antd'
import { ICreateTimelineData } from 'src/interface/org'

type Settings = ICreateTimelineData['settings']

const CreateConfig = ({
  settings,
  setSettings,
}: {
  settings: Settings
  setSettings: (val: Settings) => void
}) => {
  return (
    <div>
      <p>Which items do you want to fetch from TaskCtrl?</p>
      <div className="flex flex-col m-3 gap-2">
        <Checkbox
          checked={settings.pullDeliveries}
          onChange={(e) =>
            setSettings({ ...settings, pullDeliveries: e.target.checked })
          }
        >
          Deliveries
        </Checkbox>
        <Checkbox
          checked={settings.pullKeypoints}
          onChange={(e) =>
            setSettings({ ...settings, pullKeypoints: e.target.checked })
          }
        >
          Keypoints
        </Checkbox>
      </div>
    </div>
  )
}

export default CreateConfig
