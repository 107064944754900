import { Connector, Frame } from '@mirohq/websdk-types'
import {
  ITaskCtrlItem,
  IDeliveryDependencies,
  ITimelineData,
  ITimelineCell,
  TaskCtrlAppCard,
  TemplateCard,
  ICreateTimelineData,
} from '../interface/org'
import TableKeeperService from '../service/TableKeeperService'
import dayjs from 'dayjs'
import {
  CARD_HEIGHT,
  CARD_WIDTH,
  PADDING,
  ROW_HEIGHT,
  WEEK_WIDTH,
} from './createTimeline'
import OrgService from '../service/OrgService'
import { YearWeek } from 'src/util/time'
import { addDependency, createAppCard } from 'src/miro'
import { groupBy } from 'lodash'
import { getTimelineFrame } from '../query/miro'

type ItemWithCell = ITaskCtrlItem & { cell: ITimelineCell }
type IAppCardWithCell = TaskCtrlAppCard & { cell: ITimelineCell }
type IAppCardWithPos = TaskCtrlAppCard & { pos: { x: number; y: number } }

const CARDS_WIDE = Math.floor(WEEK_WIDTH / CARD_WIDTH)
const CARDS_TALL = Math.floor(ROW_HEIGHT / CARD_HEIGHT)
const CARD_PADDING = 4
const ITEM_WITH_CELL_KEY = 'itemWithCell'

const getPos = (
  groupNum: number,
  cell: ITimelineCell,
  frame: Frame,
): { x: number; y: number } => {
  const localY = Math.floor(groupNum % CARDS_TALL)
  const localX = Math.min(
    Math.floor(groupNum / CARDS_TALL),
    CARDS_WIDE + PADDING * 2 + groupNum,
  )

  const cellX = localX * (CARD_WIDTH + CARD_PADDING)
  const cellY = localY * (CARD_HEIGHT + CARD_PADDING)
  const frameLeft = frame.x - frame.width / 2 + PADDING
  const frameTop = frame.y - frame.height / 2 + PADDING
  return {
    x: frameLeft + cellX + cell.column * WEEK_WIDTH + CARD_WIDTH / 2,
    y: frameTop + cellY + cell.row * ROW_HEIGHT + CARD_HEIGHT / 2,
  }
}

const getCell = (
  timeline: ITimelineData,
  delivery: ITaskCtrlItem,
): ITimelineCell | undefined => {
  return timeline.cells.find(
    (cell) =>
      cell.data.disciplines.id === delivery.discipline_id &&
      cell.weekNumber.equals(new YearWeek(dayjs(delivery.endTime))),
  )
}

const getCellFromCard = (
  timeline: ITimelineData,
  item: TaskCtrlAppCard | TemplateCard,
) => {
  return timeline.cells.find(
    (cell) =>
      cell.data.disciplines.id === item.discipline?.id &&
      cell.weekNumber.equals(new YearWeek(dayjs(item.deadline))),
  )
}

const createDependency = async (dependencies: IDeliveryDependencies[]) => {
  const cards = await TableKeeperService.getAppCards()
  const promises: Promise<Connector>[] = []

  dependencies.forEach((dep) => {
    const { id, dependent_ids } = dep
    const card = cards.find((c) => c.taskCtrlId === id)
    if (!card) return

    dependent_ids.forEach((depId) => {
      const depCard = cards.find((c) => c.taskCtrlId === depId)
      if (!depCard) return

      promises.push(addDependency(card, depCard))
    })
  })
  return Promise.all(promises)
}

const pullTaskCtrlItems = async (
  projectId: number,
  timeline: ITimelineData,
  data: ICreateTimelineData,
) => {
  const frame = await getTimelineFrame(timeline.frameId)
  if (!frame) return
  const { settings, pullInfo } = data
  const { planningDates, disciplineIds } = pullInfo
  const orgDeliveries = settings.pullDeliveries
    ? await OrgService.pullDeliveries(projectId, planningDates, disciplineIds)
    : []

  const orgKeypoints = settings.pullKeypoints
    ? await OrgService.pullKeypoints(projectId, planningDates, disciplineIds)
    : []
  const all = [...orgDeliveries, ...orgKeypoints]
  const itemsWithCells: ItemWithCell[] = all
    .map((item) => ({
      ...item,
      cell: getCell(timeline, item),
    }))
    .filter((item): item is ItemWithCell => !!item.cell)

  if (settings.persistGrouping) {
    try {
      localStorage.setItem(
        ITEM_WITH_CELL_KEY,
        JSON.stringify(
          itemsWithCells.map((i) => ({
            cell: { row: i.cell.row, column: i.cell.column },
          })),
        ),
      )
    } catch (error) {
      console.error(error)
    }
  }

  const partitionedItems = groupBy(
    itemsWithCells,
    (i) => `${i.cell.row}-${i.cell.column}`,
  )

  const createCards = Object.values(partitionedItems).flatMap((group) => {
    let groupNum = 0
    return group.map((item) => {
      const pos = getPos(groupNum, item.cell, frame)
      groupNum++
      return createAppCard(item, pos, {
        saveToBackend: false,
      })
    })
  })

  const cards = await Promise.all(createCards)
  const taskCtrlCards = cards.map((p) => p.card)
  const miroCards = cards.map((p) => p.miroCard)
  await TableKeeperService.bulkSaveAppCard(taskCtrlCards)

  // make the timeline frame the parent item of the card so the cards will move with it
  miroCards.forEach((card) => {
    frame.add(card)
  })

  const dependencies = await OrgService.deliveryDependencies(
    projectId,
    planningDates,
    disciplineIds,
  )

  const keypointDependencies = await OrgService.keypointDependencies(
    projectId,
    planningDates,
    disciplineIds,
  )

  await createDependency(dependencies)
  await createDependency(keypointDependencies)
}

export const getUnsyncedCards = async (
  frame: Frame,
  items: TemplateCard[],
  timeline: ITimelineData,
): Promise<IAppCardWithPos[]> => {
  const pulledItems: IAppCardWithCell[] = JSON.parse(
    localStorage.getItem(ITEM_WITH_CELL_KEY) ?? '[]',
  )
  localStorage.removeItem(ITEM_WITH_CELL_KEY)

  const itemsWithCells: IAppCardWithCell[] = items
    .map((item) => ({
      ...item,
      cell: getCellFromCard(timeline, item),
    }))
    .filter((item): item is IAppCardWithCell => !!item.cell)

  const itemsByCell = groupBy(
    [...pulledItems, ...itemsWithCells],
    (i) => `${i.cell.row}-${i.cell.column}`,
  )
  return Object.values(itemsByCell)
    .flatMap((group) => {
      return group.map((item, index) => ({
        ...item,
        pos: getPos(index, item.cell, frame),
      }))
    })
    .filter((item) => !!item.type)
}

export default pullTaskCtrlItems
