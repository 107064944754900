import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Input } from 'antd'
import Select from '../input/Select'
import { ProjectContext } from '../../context/project/ProjectContext'
import { useCreateAppCard, useUnsyncedKeypoints } from 'src/query/tableKeeper'
import { useKeypoints, useMainProcesses, useUsers } from 'src/query/taskCtrl'
import { AppCard } from '@mirohq/websdk-types'
import { TaskCtrlAppCard } from 'src/interface/org'
import { useTimelineFrame } from 'src/query/miro'
const { TextArea } = Input

interface AddDeliveryPageProps {
  onCancel: () => void
  onSave: (card: TaskCtrlAppCard, miroCard: AppCard) => void
}

const AddDeliveryPage: FC<AddDeliveryPageProps> = ({ onCancel, onSave }) => {
  const { currentProject } = useContext(ProjectContext).state
  const unsyncedKeypoints = useUnsyncedKeypoints()

  const [selectedKeypoint, setSelectedKeypoint] = useState<number>()
  const [selectedUser, setSelectedUser] = useState<number>()
  const [selectedMainProcess, setSelectedMainProcess] = useState<number>()
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState('')

  const createNewAppCard = useCreateAppCard()

  const { data: timelineFrame } = useTimelineFrame()

  const { data: keypoints } = useKeypoints()
  const { data: users } = useUsers()
  const { data: mainProcesses } = useMainProcesses()

  const taskCtrlKeypoints = (keypoints ?? []).map((k) => ({
    ...k,
    title: k.name,
    deadline: k.endTime,
  }))

  useEffect(() => {
    setSelectedKeypoint(undefined)
    setSelectedUser(undefined)
  }, [currentProject])

  const disableButton = useMemo(() => {
    return (
      selectedKeypoint === undefined ||
      title === undefined ||
      selectedUser === undefined
    )
  }, [selectedKeypoint, title, selectedUser])

  const createAppCard = async () => {
    const keypoint = [...(taskCtrlKeypoints ?? []), ...unsyncedKeypoints].find(
      (k) => k.id === selectedKeypoint,
    )
    const reporter = users?.find((u) => u.id === selectedUser)

    if (!title || !keypoint || !reporter) {
      return
    }

    const mainProcess = mainProcesses?.find((k) => k.id === selectedMainProcess)
    if (!timelineFrame) return
    const item: Parameters<typeof createNewAppCard.mutate>[0] = {
      item: {
        type: 'delivery',
        title,
        description,
        keypoint: {
          id: Number(keypoint.id),
          label: keypoint.title,
          deadline: keypoint.deadline,
        },
        user: {
          id: reporter.id,
          label: `${reporter.firstName} ${reporter.lastName}`,
        },
        mainProcess: mainProcess
          ? { id: mainProcess.id, label: mainProcess.name }
          : undefined,
      },
      timelineFrame,
    }
    const res = await createNewAppCard.mutateAsync(item)
    onSave(res.card, res.miroCard)
  }

  const onSubmit = () => {
    if (disableButton) return
    createAppCard()
  }

  const options = useMemo(() => {
    return [
      {
        label: 'Unsynced keypoints',
        options: unsyncedKeypoints.map((k) => ({
          value: k.id,
          label: k.title,
        })),
      },
      {
        label: 'From TaskCtrl',
        options: taskCtrlKeypoints.map((k) => ({
          value: k.id,
          label: k.title,
        })),
      },
    ]
  }, [unsyncedKeypoints, taskCtrlKeypoints])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="">Title*</div>
        <Input
          size="large"
          placeholder="Add title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="">Description</div>
        <TextArea
          rows={4}
          placeholder="Add description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div>
        <Select
          onChange={setSelectedKeypoint}
          name={['name']}
          value={selectedKeypoint}
          label={'Keypoint*'}
          items={[]}
          groupOption={options}
          id={'id'}
        />
      </div>
      <div>
        <Select
          onChange={setSelectedUser}
          name={['firstName', 'lastName']}
          value={selectedUser}
          label={'Reporter*'}
          items={users ?? []}
          id={'id'}
        />
      </div>
      <div>
        <Select
          onChange={setSelectedMainProcess}
          name={['name']}
          value={selectedMainProcess}
          label={'Main process'}
          items={mainProcesses ?? []}
          id={'id'}
        />
      </div>
      <div className="flex gap-3 items-center justify-center">
        <button
          onClick={onCancel}
          className={'button sticky !mb-0 button-secondary w-1/2 '}
        >
          Cancel
        </button>
        <button
          onClick={onSubmit}
          disabled={disableButton}
          className={'button sticky bottom-0 button-primary w-1/2 '}
        >
          Add Delivery
        </button>
      </div>
    </div>
  )
}

export default AddDeliveryPage
