import { useEffect, MutableRefObject } from 'react'

type ClickOutsideHandler = (event: MouseEvent) => void

const useClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: ClickOutsideHandler,
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event)
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref, callback])
}

export default useClickOutside
