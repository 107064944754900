import { useState } from 'react'
import Spinner from '../input/Spinner'
import { clearBoard } from '../../miro'

const Logout = () => {
  const [resetInProcess, setResetInProcess] = useState(false)

  const onLogout = () => {
    localStorage.clear()
    window.location.reload()
  }

  const reset = async () => {
    if (resetInProcess) return
    setResetInProcess(true)
    await clearBoard()
    setResetInProcess(false)
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full">
        {resetInProcess ? (
          <Spinner />
        ) : (
          <button onClick={reset} className={'button button-secondary w-full'}>
            Reset board
          </button>
        )}
      </div>
      <button onClick={onLogout} className={'button  button-danger w-full'}>
        Log out
      </button>
    </div>
  )
}

export default Logout
