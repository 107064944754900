import { useMemo, useState } from 'react'
import Select from '../../components/input/Select'
import { Alert, DatePicker, InputNumber } from 'antd'
import type { Dayjs } from 'dayjs'
import { pick, uniq } from 'lodash'
import DisciplineResponsible from '../../components/timeline/DisciplineResponsible'
import {
  ICreateTimelineData,
  ICreateTimelineDataFromTemplate,
  ISingleUser,
  ITimelineData,
  ITimelineRow,
} from '../../interface/org'
import useBroadcastChanel from '../../hooks/useChannel'
import ProjectSelect from 'src/components/input/ProjectSelect'
import { useDisciplines } from 'src/query/taskCtrl'
import dayjs from 'dayjs'
import CreateConfig from './CreateConfig'
import { twMerge } from 'tailwind-merge'
import { useTemplates } from 'src/query/tableKeeper'
import { YearWeek } from 'src/util/time'

const orderByAddedRows = (timeline?: ITimelineData) => {
  if (!timeline) {
    return () => 0
  }
  const existingDisciplines = timeline.cells.reduce(
    (disciplines: number[], cell) =>
      cell.column === 0
        ? [...disciplines, cell.data.disciplines.id]
        : disciplines,
    [],
  )

  return (a: ITimelineRow, b: ITimelineRow) => {
    if (
      existingDisciplines.includes(a.disciplines.id) &&
      !existingDisciplines.includes(b.disciplines.id)
    ) {
      return -1
    } else if (
      !existingDisciplines.includes(a.disciplines.id) &&
      existingDisciplines.includes(b.disciplines.id)
    ) {
      return 1
    } else return 0
  }
}

const toDayJs = ({ year, week }: { year: number; week: number }) =>
  dayjs().year(year).week(week)

const CreateTimelineForm = ({
  timeline,
  fromTemplate,
}: {
  timeline?: ITimelineData
  fromTemplate?: boolean
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const sessionId = urlParams.get('parentSessionId') ?? ''
  const editing = !!timeline
  const { data: templates = [] } = useTemplates()
  const { data: disciplines = [] } = useDisciplines()

  const [step, setStep] = useState<1 | 2>(1)
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
  const [settings, setSettings] = useState<ICreateTimelineData['settings']>({
    pullKeypoints: true,
    pullDeliveries: true,
  })
  const [pastWeeks, setPastWeeks] = useState<number | undefined>(
    timeline?.pastWeeks ?? undefined,
  )

  const initialRows = timeline
    ? timeline.cells.reduce((rows, cell) => {
        if (cell.column === 0) {
          return { ...rows, [cell.data.disciplines.id]: cell.data }
        }
        return rows
      }, {})
    : {}

  const [rows, setRows] = useState<{ [key: number]: ITimelineRow }>(
    initialRows ?? {},
  )

  const initialPlanningDate: [Dayjs, Dayjs] | undefined = timeline
    ? [toDayJs(timeline.startWeek), toDayJs(timeline.endWeek)]
    : undefined
  const [planningDate, setPlanningDate] = useState<[Dayjs, Dayjs] | undefined>(
    initialPlanningDate,
  )

  const [startWeek, setStartWeek] = useState<Dayjs>()

  const channel = useBroadcastChanel<ICreateTimelineData>('frame:create')
  const templateChannel = useBroadcastChanel<ICreateTimelineDataFromTemplate>(
    'frame:create:template',
  )
  const updateChannel = useBroadcastChanel('frame:update')

  const initialDisciplines = Object.keys(initialRows).map(Number)

  const disabledDates = (date: Dayjs) => {
    if (!editing) return false

    return date.isBefore(
      dayjs().week(timeline.endWeek.week).year(timeline.endWeek.year),
    )
  }

  const setSelectedDisciplines = (disciplineIds: number[]) => {
    const filteredRows = pick(
      rows,
      uniq([...disciplineIds, ...initialDisciplines]),
    )
    const rowsToAdd = disciplines.filter((d) => disciplineIds.includes(d.id))

    const newRows: { [key: number]: ITimelineRow } = rowsToAdd.reduce(
      (rows, d) => ({
        ...rows,
        [d.id]: {
          disciplines: {
            id: d.id,
            longName: d.name,
            name: d.shortName,
            color: d.color,
          },
        },
      }),
      {},
    )

    setRows({ ...filteredRows, ...newRows })
  }

  const setResponsible = (discipline: number, user: ISingleUser) => {
    setRows({
      ...rows,
      [discipline]: {
        ...rows[discipline],
        responsible: {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          photoUrl: user.photoUrl,
        },
      },
    })
  }

  const onDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (!dates) return
    const [start, end] = dates
    if (!start || !end) return
    setPlanningDate([start, end])
  }

  const selectedDisciplines = useMemo(
    () => Object.keys(rows).map(Number),
    [rows],
  )

  const disableButton = useMemo(() => {
    if (selectedTemplateId && startWeek) return false

    return selectedDisciplines.length === 0 || !planningDate
  }, [selectedDisciplines, planningDate, selectedTemplateId, startWeek])

  const parsePlanningDates = () => {
    if (!planningDate) return []
    const firstWeek = planningDate[0].clone().subtract(pastWeeks ?? 0, 'week')
    const pastWeek = planningDate[1].clone()
    return [
      firstWeek.startOf('week').toISOString(),
      pastWeek.endOf('week').toISOString(),
    ]
  }

  const duration = useMemo(() => {
    if (!planningDate) return 0
    return Math.abs(planningDate[0].diff(planningDate[1], 'week')) + 1
  }, [planningDate])

  const onSubmit = async () => {
    if (disableButton || !planningDate) return
    const disciplineIds = Object.keys(rows).map(Number)

    const data: ICreateTimelineData = {
      timeline: {
        planningDate: planningDate.map((d) => d.toISOString()),
        rows: Object.values(rows).toSorted(orderByAddedRows(timeline)),
        pastWeeks: pastWeeks,
      },
      pullInfo: {
        disciplineIds,
        planningDates: parsePlanningDates(),
      },
      sessionId,
      templateData: {
        rows: Object.values(rows),
        workingWeekLength: duration,
      },
      settings,
    }
    if (editing) {
      const updateData: ICreateTimelineData = {
        ...data,
        settings: { ...settings, persistGrouping: true },
      }
      updateChannel.postMessage(updateData)
    } else {
      channel.postMessage(data)
    }
    await miro.board.ui.closeModal()
  }

  const submitFromTemplate = async () => {
    if (disableButton) return
    if (!startWeek) return
    const selectedTemplate = templates.find((t) => t.id === selectedTemplateId)
    if (!selectedTemplate) return
    const { rows } = selectedTemplate.template
    const disciplineIds = selectedTemplate.template.rows
      .filter((r) => !!r)
      .map((r) => r.disciplines.id)

    const offSet = new YearWeek(startWeek).diffInWeek(
      YearWeek.clone(selectedTemplate.startWeek),
    )

    const diff = selectedTemplate.template.workingWeekLength
    const planningDate = [
      startWeek.clone().startOf('week'),
      startWeek.clone().add(diff, 'week').endOf('week'),
    ]

    const data: ICreateTimelineDataFromTemplate = {
      timeline: {
        planningDate: planningDate.map((d) => d.toISOString()),
        rows: rows.filter((r) => r !== undefined),
        pastWeeks: pastWeeks,
      },
      pullInfo: {
        disciplineIds,
        planningDates: parsePlanningDates(),
      },
      appCards: selectedTemplate.cards,
      sessionId,
      templateData: selectedTemplate.template,
      offSet,
      settings: { ...settings, persistGrouping: true },
    }
    if (!templateChannel) {
      console.error('Could not open channel to create from template')
    }
    templateChannel?.postMessage(data)
    await miro.board.ui.closeModal()
  }

  return (
    <div className="flex flex-col gap-4 relative">
      <div className="flex flex-col gap-4">
        {step == 1 ? (
          <>
            <div className="flex gap-5">
              <div className="w-1/3">
                <ProjectSelect />
              </div>
              <div className="w-1/2">
                <div className="flex flex-col gap-2 ">
                  <div className="">Past weeks</div>
                  <InputNumber
                    placeholder="Past weeks"
                    value={pastWeeks}
                    disabled={editing}
                    type="number"
                    min={0}
                    max={52}
                    onChange={(e) => setPastWeeks(e ?? 0)}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              {fromTemplate ? (
                <>
                  <div className="flex flex-col gap-2 w-1/3">
                    <div className="">Planning period</div>
                    <DatePicker
                      picker="week"
                      onChange={(a) => a && setStartWeek(a)}
                    />
                  </div>
                  <div className="w-2/3">
                    <Select
                      onChange={setSelectedTemplateId}
                      name={['name']}
                      value={selectedTemplateId}
                      label={'Timeline template'}
                      items={templates}
                      id={'id'}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-2 w-1/3">
                    <div className="">Planning date</div>
                    <DatePicker.RangePicker
                      value={planningDate}
                      disabled={editing ? [true, false] : false}
                      disabledDate={disabledDates}
                      picker="week"
                      onChange={onDateRangeChange}
                    />
                  </div>
                  <div className="w-2/3">
                    <Select
                      onChange={setSelectedDisciplines}
                      name={['shortName', 'name']}
                      value={selectedDisciplines}
                      label={'Disciplines'}
                      items={disciplines}
                      id={'id'}
                      mode="multiple"
                    />
                  </div>
                </>
              )}
            </div>
            {editing && (
              <Alert
                closable
                type="warning"
                message={
                  'When editing the timeline, you cannot remove existing disciplines or add weeks back in time.'
                }
              />
            )}
            <hr />
            <div className="flex flex-col gap-2  mb-12">
              {Object.values(rows).map((r) => (
                <DisciplineResponsible
                  key={r.disciplines.id}
                  discipline={r.disciplines}
                  selectedResponsible={r.responsible?.id}
                  onChange={(user) => setResponsible(r.disciplines.id, user)}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <CreateConfig settings={settings} setSettings={setSettings} />
          </>
        )}
      </div>
      <div className="bottom-0 fixed bg-white w-full p-2">
        <button
          disabled={disableButton}
          className={twMerge(
            'button sticky bottom-0',
            step === 1 ? 'button-primary' : 'button-secondary',
          )}
          onClick={() => setStep(step === 1 ? 2 : 1)}
        >
          {step === 1 ? 'Next' : 'Back'}
        </button>
        {step === 2 && (
          <button
            className={'button  sticky bottom-0 button-primary '}
            onClick={fromTemplate ? submitFromTemplate : onSubmit}
          >
            {editing ? 'Save' : 'Insert'} timeline
          </button>
        )}
      </div>
    </div>
  )
}

export default CreateTimelineForm
