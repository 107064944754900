import { FC, useEffect, useState } from 'react'
import OrgInput from '../../components/input/OrgInput'
import { getMiroBoardInfo, getMiroUserInfo } from '../../util/info'
import logo from 'src/assets/logo.png'

interface ILoginPageProps {
  refresh: () => void
}

const LoginPage: FC<ILoginPageProps> = ({ refresh }) => {
  const [domain, setUrl] = useState<string>()
  const [user, setUser] = useState<string>()
  const [boardId, setBoardId] = useState<string>()
  const [refreshBool, setRefresh] = useState(false)

  useEffect(() => {
    getMiroBoardInfo().then((info) => {
      setBoardId(info?.id)
    })
    getMiroUserInfo().then((info) => {
      setUser(info?.id)
    })
  }, [])

  const onClick = () => {
    localStorage.setItem('domain', domain ?? '')
    const host = window.location.href.split('?')[0]
    const byggUrl =
      domain === 'localhost:3000' && import.meta.env.DEV
        ? 'http://localhost:3000'
        : `https://${domain}.bygg.io`
    window.open(
      `${byggUrl}/miro?boardId=${boardId}&returnUrl=${host}&miroUserId=${user}`,
      '_blank',
    )
    setRefresh(true)
  }

  return (
    <div className="flex w-full h-full justify-center items-center">
      <div className="flex flex-col gap-10 items-center">
        <img src={logo} alt="taskCtrl-logo" />
        <h1 className=" ">Connect Miro with TaskCtrl to continue</h1>
        <OrgInput onChange={setUrl} />
        <button
          onClick={refreshBool ? refresh : onClick}
          className={`button button-primary`}
          disabled={!domain}
        >
          {refreshBool ? 'Refresh' : 'Connect'}
        </button>
      </div>
    </div>
  )
}

export default LoginPage
