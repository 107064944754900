import { ISingleUser } from '../../interface/org'
import { createContext } from 'react'

export interface IUserContext {
  actions: {
    reload: () => void
  }
  state: {
    user?: ISingleUser
    loading: boolean
    notConnected?: boolean
    sessionId: string
  }
}

export const UserContext = createContext({} as IUserContext)
