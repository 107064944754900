import { DescriptionsProps, Descriptions } from 'antd'
import { FC } from 'react'
import { TaskCtrlAppCard } from 'src/interface/org'
import { taskCtrlLink } from 'src/util/const/util'
import { ReloadOutlined } from '@ant-design/icons'
import { formatDate } from '../../util/time'

interface CardInfoProps {
  item: TaskCtrlAppCard
  reloadInfo: () => void
}

const ItemInfo: FC<CardInfoProps> = ({ item, reloadInfo }) => {
  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Responsible',
      children: item.responsible?.label,
    },
    {
      key: '2',
      label: 'Description',
      children: item.description,
    },
    {
      key: '3',
      label: 'Discipline',
      children: item.discipline?.label,
    },
    {
      key: '4',
      label: 'Deadline',
      children: formatDate(item.deadline),
    },
    item.type === 'keypoint'
      ? {
          key: '5',
          label: 'Milestone',
          children: item.mile_stone?.label,
        }
      : {
          key: '5',
          label: 'Keypoint',
          children: item.keypoint?.label,
        },
    {
      key: '6',
      label: 'Main process',
      children: item.mainProcess?.label,
    },
    {
      key: '7',
      label: 'Card type',
      children: item.type === 'keypoint' ? 'Keypoint' : 'Delivery',
    },
  ]

  return (
    <Descriptions
      size="small"
      bordered
      title={
        <div className="flex justify-between">
          <span title={item.title} className="truncate">
            {item.title}
          </span>
          {item.taskCtrlId ? (
            <a
              className="text-xs link text-blue-600"
              target="_blank"
              rel="noreferrer"
              href={taskCtrlLink(
                `/${item.type}/table_overview/${item.type}/${item.taskCtrlId}`,
              )}
            >
              View in TaskCtrl
            </a>
          ) : (
            <ReloadOutlined
              onClick={reloadInfo}
              className="text-lg hover:opacity-40 cursor-pointer"
            />
          )}
        </div>
      }
      items={items}
    />
  )
}

export default ItemInfo
