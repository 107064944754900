import dayjs, { Dayjs } from 'dayjs'
import { ITimelineCell, ITimelineData, TaskCtrlAppCard } from '../interface/org'
import { formatDate, YearWeek } from './time'
import _, { toNumber } from 'lodash'
import { isDelivery, itemReadyForSync } from '../models/TaskCtrlItem'
import { queryClient } from '../app'
import TableKeeperService from '../service/TableKeeperService'
import { updateMiroAppCard } from '../miro'

const UN_ASSIGNED = -1

export const isWithinWeek = (deadline?: string, yearWeek?: YearWeek) => {
  if (!deadline || !yearWeek) return false
  const deadlineToDayJs = dayjs(deadline)
  const startOfWeek = dayjs().week(yearWeek.week).year(yearWeek.year).day(0)
  const endOfWeek = dayjs().week(yearWeek.week).year(yearWeek.year).day(6)

  return deadlineToDayJs >= startOfWeek && deadlineToDayJs <= endOfWeek
}

export const shouldUpdateDeadline = (
  existingCard: TaskCtrlAppCard,
  syncedWithTaskCtrl: boolean | undefined | number,
  timelineCell?: ITimelineCell,
) => {
  if (!existingCard?.deadline || !syncedWithTaskCtrl) return true
  return !isWithinWeek(existingCard.deadline, timelineCell?.weekNumber)
}

export const syncedWithTaskCtrlVersion = (
  cardUpdates: Partial<TaskCtrlAppCard>,
  taskCtrlVersion?: TaskCtrlAppCard,
): boolean => {
  if (!taskCtrlVersion) return false

  const fieldsToCompare = [
    'responsible.id',
    'discipline.id',
    'title',
    'description',
    'mainProcess.id',
    'user.id',
    'keypoint.id',
    'mile_stone.id',
  ]

  const areFieldsEqual = fieldsToCompare.every(
    (field) => _.get(cardUpdates, field) === _.get(taskCtrlVersion, field),
  )

  const taskCtrlDeadline = dayjs(taskCtrlVersion?.deadline)
  const updatedDeadline = dayjs(cardUpdates?.deadline)

  const areDeadlinesEqual = updatedDeadline
    .startOf('day')
    .isSame(taskCtrlDeadline.startOf('day'))
  const isWithinSameWeek = taskCtrlDeadline.week() == updatedDeadline.week()

  // If the TaskCtrl item has another date within that week, and the cards is moved. It should not be set to un-synced
  return (areDeadlinesEqual || isWithinSameWeek) && areFieldsEqual
}

export const itemSyncErrors = (
  card: TaskCtrlAppCard,
  timeline?: ITimelineData,
) => {
  const errors = [
    (!card.discipline || card.discipline.id === UN_ASSIGNED) &&
      'Outside timeline',
    card.deadline == undefined && 'Outside timeline',
    !isInPlanningPeriod(dayjs(card?.deadline), timeline) &&
      'Outside planning period',
    isInFreezePeriod(card, timeline) &&
      `${card.type} is inside its freeze period`,
    deadlineAfterKeyPoint(card) &&
      `The delivery's deadline is after it's corresponding keypoint (${formatDate(card.keypoint?.deadline)})`,
    deadlineAfterMileStone(card) &&
      `The keypoint's deadline is after it's corresponding milestone (${formatDate(card.mile_stone?.deadline)})`,
    card.responsible?.id == undefined && 'Missing responsible',
    card.user?.id == undefined && 'Missing assignee',
    isDelivery(card) && card.keypoint === undefined && 'Missing key point',
  ].filter((val): val is string => typeof val == 'string')

  return errors
}

export const isInPlanningPeriod = (
  deadline: Dayjs,
  timeline?: ITimelineData,
) => {
  if (!timeline) return true
  const startOfPlanning = dayjs()
    .week(timeline.startWeek.week)
    .year(timeline.startWeek.year)
    .day(1)
  return dayjs(deadline) >= startOfPlanning
}

export const isInFreezePeriod = (
  card: TaskCtrlAppCard,
  timeline?: ITimelineData,
) => {
  const freezeDuration =
    card.type == 'delivery'
      ? timeline?.deliveryFreezeDuration
      : timeline?.keyPointFreezeDuration

  if (!freezeDuration) return false

  const newDate = dayjs(card.deadline)
  const previousDate = dayjs(card.taskCtrlVersion?.deadline)
  const startOfFreeze = dayjs().startOf('week')
  const endOfFreeze = startOfFreeze
    .add(freezeDuration - 1, 'week')
    .endOf('week')

  const taskCtrlVersionInFreeze =
    previousDate.isAfter(startOfFreeze) && previousDate.isBefore(endOfFreeze)
  const currentVersionInFreeze =
    newDate.isAfter(startOfFreeze) && newDate.isBefore(endOfFreeze)

  // If the item from TaskCtrl was already in the freeze period, then it should not trigger an error if it is moved there
  return (
    currentVersionInFreeze &&
    (!taskCtrlVersionInFreeze || !card.taskCtrlVersion)
  )
}

export const deadlineAfterKeyPoint = (card: TaskCtrlAppCard) => {
  if (card.type == 'keypoint') return false
  return dayjs(card.deadline)
    .startOf('day')
    .isAfter(dayjs(card.keypoint?.deadline).startOf('day'))
}

export const deadlineAfterMileStone = (card: TaskCtrlAppCard) => {
  if (card.type == 'delivery' || !card.mile_stone) return false
  return dayjs(card.deadline)
    .startOf('day')
    .isAfter(dayjs(card.mile_stone?.deadline).startOf('day'))
}

export const onKeyPointUpdate = async (keypointCard: TaskCtrlAppCard) => {
  const data: TaskCtrlAppCard[] | undefined = await queryClient.getQueryData([
    'appCards',
  ])

  const kpExistsInTaskCtrl = keypointCard.taskCtrlId

  const deliveries = data?.filter((card) =>
    kpExistsInTaskCtrl
      ? card.keypoint?.id == kpExistsInTaskCtrl
      : card.keypoint?.id == toNumber(keypointCard.id),
  )
  if (!deliveries || deliveries?.length <= 0) return

  const newAppCards = await Promise.all(
    deliveries.map(async (delivery) => {
      const [mCard] = await miro.board.get({
        type: 'app_card',
        id: delivery.id,
      })

      const tCardFirst = {
        ...delivery,
        keypoint: {
          id: keypointCard.taskCtrlId ?? toNumber(keypointCard.id),
          deadline: keypointCard.deadline,
          label: keypointCard.title,
        },
      }

      const tCard = {
        ...tCardFirst,
        synced: itemReadyForSync(tCardFirst, undefined),
      }

      await updateMiroAppCard(mCard, tCard, undefined)
      return tCard
    }),
  )

  await TableKeeperService.bulkSaveAppCard(newAppCards)
}
