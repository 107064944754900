import { jwtDecode, JwtPayload } from 'jwt-decode'
import { IAppUser } from '../interface/org'
import axios from 'axios'

class BaseService {
  private wallToken = ''
  private orgApi = ''
  private keeperApi = ''
  private proxyUrl = ''
  private org: boolean

  constructor(orgApi: boolean) {
    this.org = orgApi
    this.init()
  }

  protected init = () => {
    const userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      const info: IAppUser = JSON.parse(userInfo)
      this.wallToken = info.wallToken
      this.orgApi = info.domain
      this.keeperApi = this.keeperUrl(info)
      this.proxyUrl = info.proxyUrl
    }
  }

  private keeperUrl = (info: IAppUser) => {
    return `${info.keeperUrl}miro/domain/${info.host}`
  }

  private moreThanFourMinutes = (iat: number) => {
    const now = new Date().getTime() / 1000
    return now - iat > 240
  }

  private persistRefreshToken = (refreshToken: string) => {
    this.wallToken = refreshToken
    const userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      const info: IAppUser = JSON.parse(userInfo)
      info.wallToken = refreshToken
      localStorage.setItem('userInfo', JSON.stringify(info))
    }
  }

  private updateToken = async (): Promise<string> => {
    const token = window.localStorage.getItem('token')
    if (token) {
      const decodedToken: JwtPayload = jwtDecode(token)
      if (!decodedToken.iat) throw new Error('Malformed JWT token')
      const shouldUpdateToken = this.moreThanFourMinutes(decodedToken.iat)
      if (!shouldUpdateToken) {
        return Promise.resolve(token)
      } else {
        return this.pullToken()
      }
    }
    return this.pullToken()
  }

  private pullToken = async (): Promise<string> => {
    const { data } = await axios.get(`${this.proxyUrl}/issue-new-token/`, {
      headers: {
        Authorization: `${this.wallToken}`,
      },
    })
    const { accessToken, refreshToken } = data.body
    window.localStorage.setItem('token', accessToken)
    this.persistRefreshToken(refreshToken)
    return accessToken
  }

  public get = async <T>(url: string, projectId?: number) => {
    const token = await this.updateToken()
    const { data } = await axios.get<T>(
      `${this.org ? this.orgApi : this.keeperApi}/${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Project-Id': projectId,
        },
      },
    )
    return data
  }

  public post = async (url: string, body: unknown, projectId?: number) => {
    const token = await this.updateToken()
    const { data } = await axios.post(
      `${this.org ? this.orgApi : this.keeperApi}/${url}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Project-Id': projectId,
        },
      },
    )
    return data
  }

  public put = async (url: string, body: unknown, projectId?: number) => {
    const token = await this.updateToken()
    const { data } = await axios.put(
      `${this.org ? this.orgApi : this.keeperApi}/${url}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Project-Id': projectId,
        },
      },
    )
    return data
  }

  public delete = async (url: string, projectId?: number) => {
    const token = await this.updateToken()
    const { data } = await axios.delete(
      `${this.org ? this.orgApi : this.keeperApi}/${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Project-Id': projectId,
        },
      },
    )
    return data
  }
}

export default BaseService
