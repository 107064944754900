import { UserInfo, BoardInfo } from '@mirohq/websdk-types'

const cachedInfo = <T>(key: string) => {
  return new Promise<T | undefined>((resolve) => {
    setTimeout(() => {
      const userInfo = localStorage.getItem(key)
      if (userInfo) {
        resolve(JSON.parse(userInfo))
      } else {
        resolve(undefined)
      }
    }, 1000)
  })
}

const miroUserInfo = async () => {
  const info = await miro.board.getUserInfo()
  localStorage.setItem('miroUserInfo', JSON.stringify(info))
  return info
}
const miroBoardInfo = async () => {
  const info = await miro.board.getInfo()
  localStorage.setItem('miroBoardInfo', JSON.stringify(info))
  return info
}

export const getMiroUserInfo = (): Promise<UserInfo | undefined> => {
  return Promise.race([miroUserInfo(), cachedInfo<UserInfo>('miroUserInfo')])
}

export const getMiroBoardInfo = (): Promise<BoardInfo | undefined> => {
  return Promise.race([miroBoardInfo(), cachedInfo<BoardInfo>('miroBoardInfo')])
}
