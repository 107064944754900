import { UUID } from 'crypto'
import { YearWeek } from 'src/util/time'
import { Required } from 'utility-types'

type IDWithLabel = {
  id: number
  label: string
  deadline?: string
}

type IDWithLabelAndColor = IDWithLabel & { color?: string }

type IPosition = { x: number; y: number }

export type TaskCtrlAppCard = {
  id: string
  type: 'delivery' | 'keypoint'
  title: string
  description: string
  createdBy: string
  user?: IDWithLabel
  responsible?: IDWithLabel
  deadline?: string
  discipline?: IDWithLabelAndColor
  mainProcess?: IDWithLabel
  taskCtrlId?: number
  mile_stone?: IDWithLabel
  keypoint?: IDWithLabel
  position?: IPosition
  synced?: boolean
  taskCtrlVersion?: TaskCtrlAppCard
}

export interface IAppUser {
  domain: string
  keeperUrl: string
  host: string
  wallToken: string
  proxyUrl: string
  miroUserId: string
}

export interface ISingleUser {
  id: number
  name: string
  email: string
  photoUrl?: string
  userId: number
  firstName: string
  lastName: string
}

export interface IProject {
  id: number
  projectName: string
  projectNumber: string
  organization_id?: number
  areal: string
  image_url: string
  logo?: string
  planning_module: boolean
  construction_module: boolean
  systematic_completion_module: boolean
  document_control_module: boolean
  risk_module: boolean
  improvement_module: boolean
  meeting_module: boolean
  task_module: boolean
  is_infrastructure_project: boolean
}

export interface IDiscipline {
  id: number
  name: string
  shortName: string
  color: string
  shape: string
  sequence: number
  contract_id: number
  project_id?: number
  created_at?: string
  updated_at?: string
}

export interface ITag {
  id?: number
  record_id?: string
  name: string
  description?: string
  sequence?: number
}
export interface IUserData {
  id: number
  firstName: string
  lastName: string
  email: string
  telephoneNumber: string
  key: string
  isSystemAdmin: boolean
  projects?: IProject[]
  disciplines?: IDiscipline[]
  project_id?: number
  contract_id?: number
  discipline_id?: number
  role_id?: number
  isQualityAdmin?: boolean
  isProjectAdmin?: boolean
  isContractAdmin?: boolean
  isProjectRiskAdmin?: boolean
  isBaselineAdmin?: boolean
  meetingModuleAccess?: boolean
  statusManager?: boolean
  image_url?: string
  isActive?: boolean
  accessExpire?: string | null
  email_notification?: boolean
  language?: string
}

export interface ITaskData {
  id: number
  title: string
  description: string
  status: string
  taskType: string
  deadline: string
  endDate?: string | null
  closed_date?: string
  responsible_id: number
  parent_id: number
  parent_type: string
  discipline_id: number
  contract_id?: number | undefined
  system_id: number | undefined
  test_id?: number | null
  test_case_id?: number | null
  location_id: number | undefined
  construction_locomotive_id?: number
  control_area_id?: number
  project_id: number
  task_type_id: number | null | undefined
  task_step_id?: number
  is_subtask?: boolean
  reporter_id?: number
  update_access?: boolean
  delete_access?: boolean
  record_id?: string
  responsible?: IUserData
  reporter?: IUserData
  discipline?: IDiscipline
  duration?: number
  startTime?: number | null
  improvement_id?: number | undefined
  category?: string
  created_at?: string
  updated_at?: string
  tag_id?: number | null
  tag?: ITag
  system_type_group_id?: number
  delivery_id?: number
  related_tasks?: ITaskData[]
  related_task_ids?: number[]
  related_system_ids?: number[]
  isActive?: boolean
}

export interface IMainProcess {
  id: number
  name: string
  record_id?: string
}

export interface ITaskCtrlItem {
  id: number | string
  name: string
  record_id?: string
  description: string
  main_process?: IMainProcess
  responsible?: IUserData
  discipline?: IDiscipline
  user?: IUserData
  main_process_id?: number
  discipline_id?: number
  startTime?: string
  endTime?: string
  responsible_id: number | null
  user_id?: number
  taskCtrlId?: number
  idempotency?: UUID
  status?: 'done' | 'in_progress' | 'open'
}

export type TemplateCard = Omit<TaskCtrlAppCard, 'id'>
export type ICreateDelivery = Required<Omit<IDelivery, 'id'>, 'idempotency'>
export type IUpdateDelivery = Required<IDelivery, 'idempotency'>

export type ICreateKeyPoint = Required<Omit<IKeypoint, 'id'>, 'idempotency'>
export type IUpdateKeyPoint = Required<IKeypoint, 'idempotency'>

export interface IMilestone {
  name: string
  record_id?: string
  id: number
  deadline: string
}

export interface IKeypoint extends ITaskCtrlItem {
  mile_stone_id?: number
  mile_stone?: IMilestone
  endTime?: string
}
export interface IDelivery extends ITaskCtrlItem {
  key_point_id?: number | string
  key_point?: IKeypoint
}

export interface IDeliveryDependencies {
  id: number
  dependent_on_ids: number[]
  dependent_ids: number[]
}

export interface ITimelineRow {
  disciplines: {
    id: number
    name: string
    color: string
  }
  responsible?: {
    id: number
    name: string
    photoUrl?: string
  }
}

export interface ITimelineCell {
  column: number
  row: number
  data: ITimelineRow
  endOfWeek: string
  weekNumber: YearWeek
  x?: number
  y?: number
}

export interface IOptionLabel {
  value: string
  label: string
  deadline?: string
}

export interface ITemplateData {
  rows: ITimelineRow[]
  workingWeekLength: number
}

export interface IDisciplineData {
  id: number
  name: string
  shortName: string
  color: string
  shape: string
  sequence: number
  contract_id: number
  project_id?: number
}

export interface ICreateTimelineData {
  timeline: {
    planningDate: string[]
    rows: ITimelineRow[]
    pastWeeks?: number
  }
  pullInfo: {
    disciplineIds: number[]
    planningDates: string[]
  }
  sessionId: string
  templateData: ITemplateData
  settings: {
    pullDeliveries: boolean
    pullKeypoints: boolean
    persistGrouping?: boolean
  }
}

export interface ICreateTimelineDataFromTemplate extends ICreateTimelineData {
  appCards: TemplateCard[]
  offSet: number
}

export interface ITimelineData {
  frameId: string
  projectId: number
  numberOfWeeks: number
  numberOfDisciplines: number
  padding: number
  cells: ITimelineCell[]
  startWeek: YearWeek
  endWeek: YearWeek
  templateData: ITemplateData
  pastWeeks: number
  deliveryFreezeDuration?: number
  keyPointFreezeDuration?: number
}

export enum BroadcastEvent {
  CARD_CREATE,
  CARD_DELETE,
  FRAME_CREATE,
  FRAME_DELETE,
  DELETE_APP_CARD,
  CARD_UPDATE,
  SYNC_COMPLETE,
}

export interface IBroadcastEvent {
  event: BroadcastEvent
}

export interface ITemplate {
  id: string
  name: string
  template: ITemplateData
  cards: TemplateCard[]
  createdAt: string
  startWeek: YearWeek
}

export type IdempotencyResponse = {
  idempotency: { [key: UUID]: number }
}

export type IFreezePeriod = {
  plan_freeze_period: number
}

export interface ICardGroup {
  cell: {
    row: number
    column: number
  }
}
