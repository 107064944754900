import { Spin } from 'antd'

interface IProps {
  centerScreen?: boolean
}
const Spinner = ({ centerScreen }: IProps) => {
  return (
    <div className={centerScreen ? 'fixed top-1/2 left-1/2' : ''} role="status">
      <Spin size="large" />
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default Spinner
