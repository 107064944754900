import Feedback from 'mirotone/dist/icons/comment-feedback.svg'
import Close from 'mirotone/dist/icons/close.svg'
import { useState } from 'react'

import { twMerge } from 'tailwind-merge'

const Footer = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false)

  return (
    <div className="h-[5%] w-full flex flex-col">
      <div className="flex-1 flex justify-end mx-2 items-center z-10">
        <Feedback
          className="hover:fill-blue-900 cursor-pointer"
          onClick={() => setFeedbackOpen(!feedbackOpen)}
        />
      </div>
      <div
        className={twMerge(
          'h-48 w-full bg-white rounded-t fixed bottom-0 p-2 translate-y-full',
          'transition-transform',
          feedbackOpen && 'translate-y-0 shadow-lg shadow-current ',
        )}
      >
        <div className="flex justify-between">
          <h2>Support</h2>
          <Close
            className="cursor-pointer hover:fill-blue-900"
            onClick={() => setFeedbackOpen(false)}
          />
        </div>
        <hr />
        <p className="text-gray-500 text-sm">
          Do you have any questions or feedback regarding the TaskCtrl Miro
          application?
        </p>
        <br />
        <p className="text-sm">
          Feel free to send us an email at{' '}
          <a className="link" href="mailto:support@taskctrl.no">
            support@taskctrl.no
          </a>{' '}
          or use our contact form{' '}
          <a
            href="https://taskctrl.no/en-GB/contact"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            here
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default Footer
