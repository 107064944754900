import {
  AppCard,
  AppCardProps,
  BoardNode,
  CardField,
  Frame,
  Item,
} from '@mirohq/websdk-types'
import type { Optional } from 'utility-types'
import {
  IDelivery,
  IKeypoint,
  ITaskCtrlItem,
  ITimelineData,
  ITimelineRow,
  TaskCtrlAppCard,
} from 'src/interface/org'
import { itemReadyForSync } from './models/TaskCtrlItem'
import { YearWeek } from './util/time'
import TableKeeperService from './service/TableKeeperService'
import {
  CARD_WIDTH,
  PADDING,
  ROW_HEIGHT,
  WEEK_WIDTH,
  numberOfWeeks,
} from './util/createTimeline'
import { addRandomNumber } from './util/miro'

const toTaskCtrlAppCard = (
  item: ITaskCtrlItem,
): Omit<TaskCtrlAppCard, 'id' | 'createdBy'> => {
  const isDelivery = isDeliveryItem(item)
  const isKeyPoint = isKeyPointItem(item)

  const card: Omit<TaskCtrlAppCard, 'id' | 'createdBy'> = {
    type: isDelivery ? 'delivery' : 'keypoint',
    title: item.name,
    description: item.description,
  }

  if (item.main_process?.id) {
    card.mainProcess = {
      id: item.main_process.id,
      label: item.main_process.name,
    }
  }

  if (isDelivery && item.key_point?.id) {
    card.keypoint = {
      id: Number(item.key_point.id),
      label: item.key_point.name,
      deadline: item.key_point.endTime,
    }
  }

  if (isKeyPoint && item.mile_stone?.id) {
    card.mile_stone = {
      id: Number(item.mile_stone.id),
      label: item.mile_stone.name,
      deadline: item.mile_stone.deadline,
    }
  }

  if (item.responsible) {
    card.responsible = {
      id: item.responsible.id,
      label: `${item.responsible.firstName} ${item.responsible.lastName}`,
    }
  }

  if (item.discipline) {
    card.discipline = {
      id: item.discipline.id,
      label: item.discipline.shortName,
      color: item.discipline.color,
    }
  }

  if (item.user) {
    card.user = {
      id: item.user.id,
      label: `${item.user.firstName} ${item.user.lastName}`,
    }
  }

  if (item.endTime) {
    card.deadline = item.endTime
  }

  if (item.id) {
    card.taskCtrlId = Number(item.id)
  }

  return card
}

const getFields = (
  taskCtrlItem: Optional<TaskCtrlAppCard, 'id' | 'createdBy'>,
  {
    isDelivery,
    existingFields,
    status,
  }: {
    isDelivery?: boolean
    existingFields?: CardField[]
    status?: 'open' | 'in_progress' | 'done'
  } = {
    isDelivery: true,
  },
) => {
  const fields = existingFields ? [...existingFields] : []

  if (taskCtrlItem.taskCtrlId) {
    fields.push({
      tooltip: `Saved in TaskCtrl (${taskCtrlItem.taskCtrlId})`,
      iconUrl: 'https://cdn-icons-png.flaticon.com/512/1000/1000896.png',
      fillColor: '#43bc4f',
    })
  }

  if (taskCtrlItem?.type === 'keypoint') {
    fields.push({
      value: 'Keypoint',
      tooltip: `Keypoint`,
      iconUrl: 'https://cdn-icons-png.flaticon.com/512/1002/1002281.png',
      textColor: '#fff',
      fillColor: '#fbc500',
    })
  }

  if (taskCtrlItem?.type === 'delivery') {
    fields.push({
      value: 'Delivery',
      tooltip: `Delivery`,
      iconUrl: 'https://cdn-icons-png.flaticon.com/512/1827/1827323.png',
      textColor: '#fff',
      fillColor: '#269bf7',
    })
  }

  if (status) {
    fields.push(statusField(status))
  }

  if (isDelivery && taskCtrlItem.keypoint) {
    const { id, label } = taskCtrlItem.keypoint
    fields.push({
      value: label,
      tooltip: `Connected keypoint: ${label} (${id})`,
      iconUrl: 'https://cdn-icons-png.flaticon.com/512/1002/1002281.png',
    })
  }

  if (taskCtrlItem.responsible) {
    const { id, label } = taskCtrlItem.responsible
    fields.push({
      value: label,
      tooltip: `Responsible: ${label} (${id})`,
      iconUrl: 'https://cdn-icons-png.flaticon.com/512/921/921124.png',
      iconShape: 'round',
    })
  }

  if (taskCtrlItem.mile_stone) {
    const { id, label } = taskCtrlItem.mile_stone
    fields.push({
      value: label,
      tooltip: `Milestone: ${label} (${id})`,
      iconUrl: 'https://cdn-icons-png.flaticon.com/512/1000/1000869.png',
      iconShape: 'square',
    })
  }

  return fields
}

export const openModal = async (url: string) => {
  await miro.board.ui.openModal({
    url,
  })
}

const statusField = (status: 'done' | 'in_progress' | 'open'): CardField => {
  let value = ''
  let fillColor = ''
  switch (status) {
    case 'open':
      value = 'Open'
      fillColor = '#f6e05e'
      break
    case 'done':
      value = 'Done'
      fillColor = '#48bb78'
      break
    case 'in_progress':
      value = 'In progress'
      fillColor = '#4299e1'
      break
    default:
      break
  }

  return {
    value,
    tooltip: `Status: ${value}`,
    fillColor,
    textColor: '#fff',
  }
}

const isDeliveryItem = (item: ITaskCtrlItem): item is IDelivery =>
  'key_point_id' in item
const isKeyPointItem = (item: ITaskCtrlItem): item is IKeypoint =>
  !('key_point_id' in item)

export const createAppCard = async <T extends ITaskCtrlItem>(
  taskCtrlItem: T,
  pos: { x: number; y: number },
  options: { saveToBackend?: boolean; createProps?: Partial<AppCardProps> } = {
    saveToBackend: true,
    createProps: {},
  },
) => {
  const taskCtrlCard = toTaskCtrlAppCard(taskCtrlItem)
  const fields = getFields(taskCtrlCard, {
    status: taskCtrlItem.status,
  })
  const isDelivery = isDeliveryItem(taskCtrlItem)
  const { saveToBackend, createProps } = options

  const card = await miro.board.createAppCard({
    title: taskCtrlItem.name,
    description: taskCtrlItem.description ?? '',
    width: CARD_WIDTH,
    style: {
      cardTheme:
        taskCtrlItem.discipline?.color ?? (isDelivery ? '#269bf7' : '#FDF445'),
      fillBackground: true,
    },
    fields,
    ...pos,
    status: 'connected',
    ...createProps,
  })

  if (saveToBackend) await TableKeeperService.saveAppCard(taskCtrlCard)

  card.setMetadata('id', card.id)

  const taskCtrlVersion: TaskCtrlAppCard = {
    ...taskCtrlCard,
    id: card.id,
    createdBy: card.createdBy,
    position: pos,
    synced: true,
  }

  // Set taskCtrlVersion to check sync differences when moving the card
  const tCard = {
    ...taskCtrlVersion,
    taskCtrlVersion: taskCtrlVersion,
  }

  return {
    card: tCard,
    miroCard: card,
  }
}

type CreateCardProps = {
  pos?: { x?: number; y?: number }
}

export const createNewMiroCard = async (
  item: Omit<TaskCtrlAppCard, 'id' | 'createdBy'>,
  timelinePos: Frame,
  { pos }: CreateCardProps,
) => {
  const fields = getFields(item)

  const card = await miro.board.createAppCard({
    title: item.title,
    description: item.description,
    style: {
      cardTheme: item.discipline?.color ?? '#FF0000',
      fillBackground: !!item.discipline,
    },
    fields,
    width: CARD_WIDTH,
    x:
      pos?.x ??
      addRandomNumber(timelinePos.x + timelinePos.width / 2 - 200, 600),
    y:
      pos?.y ??
      addRandomNumber(timelinePos.y - timelinePos.height / 2 - 400, 600),
    status: 'connected',
  })

  card.setMetadata('id', card.id)

  return card
}

export const updateMiroAppCard = async (
  miroCard: AppCard,
  card: TaskCtrlAppCard,
  timeline?: ITimelineData,
) => {
  const fields = getFields(card)
  miroCard.fields = fields
  miroCard.title = card.title
  miroCard.description = card.description

  const hasErrors = !itemReadyForSync(card, timeline)
  miroCard.style.cardTheme = hasErrors
    ? '#FF0000'
    : card.discipline?.color ?? '#fff'
  miroCard.style.fillBackground = !hasErrors
  await miroCard.sync()
}

export const createTimelineFrame = async ({
  timeline,
  rows,
  frameStartWeek,
  offset,
  existingOffset,
}: {
  timeline: Optional<ITimelineData, 'frameId'>
  rows: ITimelineRow[]
  frameStartWeek: YearWeek
  offset: { x: number; y: number }
  existingOffset: { x: number; y: number }
}) => {
  const rowCount = timeline.numberOfDisciplines

  const frameWidth = timeline.numberOfWeeks * WEEK_WIDTH + PADDING * 2
  const halfFrameWidth = frameWidth / 2 - WEEK_WIDTH / 2 - PADDING
  const frameHeight = (rowCount ?? 1) * ROW_HEIGHT + PADDING * 2
  const halfFrameHeight = frameHeight / 2 - ROW_HEIGHT / 2 - PADDING

  const { startWeek, endWeek } = timeline
  const weekNumbers = numberOfWeeks(frameStartWeek, endWeek)

  const timelinePromises: Promise<Item>[] = []

  const offsetX = existingOffset.x + (offset.x * WEEK_WIDTH) / 2
  const offsetY = existingOffset.y + (offset.y * ROW_HEIGHT) / 2

  // create a background frame to hold the timeline and lock it
  const frame = await miro.board.createFrame({
    style: {
      fillColor: '#fff',
    },
    x: offsetX,
    y: offsetY,
    width: frameWidth,
    height: frameHeight,
  })

  for (let i = 0; i < rowCount; i++) {
    const rowObject = rows[i]
    const ySpacing = -halfFrameHeight + i * ROW_HEIGHT - PADDING / 2
    timelinePromises.push(
      miro.board.createText({
        content: `${rowObject.disciplines.name}`,
        x: offsetX - halfFrameWidth - 700,
        y: offsetY + ySpacing + PADDING / 2,
        width: PADDING,
        height: ROW_HEIGHT / 2,
        style: {
          fontSize: 138,
          textAlign: 'center',
        },
        rotation: 270,
      }),
    )
    timelinePromises.push(
      miro.board.createShape({
        style: {
          fillColor: rowObject.disciplines.color,
          fillOpacity: 0.1,
        },
        x: offsetX,
        y: offsetY + ySpacing + PADDING / 2,
        width: weekNumbers.length * WEEK_WIDTH,
        height: ROW_HEIGHT,
      }),
    )
  }

  weekNumbers.map(async (day: YearWeek, columnIndex: number) => {
    // create box for each week inside the background frame
    const xSpacing = -halfFrameWidth + columnIndex * WEEK_WIDTH
    timelinePromises.push(
      miro.board.createText({
        content: day.toString(),
        x: offsetX + xSpacing,
        y: offsetY + -halfFrameHeight - 500,
        width: WEEK_WIDTH,
        height: PADDING / 2,
        style: {
          fontSize: 144,
          textAlign: 'center',
          color: day.before(startWeek) ? '#CCC' : '#000',
        },
      }),
    )
    timelinePromises.push(
      miro.board.createShape({
        style: {
          fillColor: day.before(startWeek) ? '#CCC' : 'transparent',
          borderColor: '#000',
          borderOpacity: 1,
          borderWidth: 2,
        },
        x: offsetX + xSpacing,
        y: offsetY + 0,
        width: WEEK_WIDTH,
        height: (rowCount ?? 1) * ROW_HEIGHT,
      }),
    )
  })

  const timelineItems = await Promise.all(timelinePromises)
  Promise.all(
    timelineItems
      .map((item) => {
        if (item.type === 'text' || item.type === 'shape') {
          return Promise.all([item.sendToBack(), frame.add(item)])
        }
        return false
      })
      .filter(Boolean),
  )

  return { timelineItems, frame }
}

export const clearBoard = async () => {
  const { board } = window.miro
  const items: BoardNode[] = await board.get()

  try {
    await Promise.all(
      items.map(async (item) => board.remove(await board.getById(item.id))),
    )
  } catch (e) {
    console.log(e)
  }
}

export const addDependency = (from: TaskCtrlAppCard, to: TaskCtrlAppCard) => {
  return miro.board.createConnector({
    shape: 'curved',
    style: {
      strokeStyle: 'normal',
      strokeColor: '#269bf7',
      strokeWidth: 8,
    },
    end: {
      item: from.id,
      snapTo: 'left',
    },
    start: {
      item: to.id,
      snapTo: 'right',
    },
  })
}
