import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { ProjectContext } from 'src/context/project/ProjectContext'
import OrgService from 'src/service/OrgService'

export const useDisciplines = () => {
  const { currentProject: projectId } = useContext(ProjectContext).state

  return useQuery({
    queryKey: ['disciplines', { project: projectId }],
    queryFn: () => OrgService.getUserDisciplines(projectId),
  })
}

export const useDisciplineUsers = ({ discipline }: { discipline?: number }) => {
  const { currentProject: projectId } = useContext(ProjectContext).state
  return useQuery({
    queryKey: ['disciplines', discipline, 'users'],
    queryFn: !!discipline
      ? () => OrgService.getDisciplineUsers(discipline, projectId)
      : () => Promise.resolve([]),
    enabled: !!discipline && discipline >= 0,
  })
}

export const useDiscipline = (id?: number) => {
  const { currentProject: projectId } = useContext(ProjectContext).state
  return useQuery({
    queryKey: ['disciplines', id],
    queryFn: () =>
      !!id
        ? OrgService.getUserDiscipline(id, projectId)
        : Promise.resolve(undefined),
    enabled: !!id,
  })
}

export const useKeypoints = () => {
  const { currentProject: projectId } = useContext(ProjectContext).state

  return useQuery({
    queryKey: ['keypoints', projectId],
    queryFn: () => OrgService.getProjectKeypoints(projectId),
  })
}

export const useUsers = () => {
  const { currentProject: projectId } = useContext(ProjectContext).state

  return useQuery({
    queryKey: ['users', projectId],
    queryFn: () => OrgService.getProjectUsers(projectId),
  })
}

export const useMainProcesses = () => {
  const { currentProject: projectId } = useContext(ProjectContext).state

  return useQuery({
    queryKey: ['mainProcess', projectId],
    queryFn: () => OrgService.getProjectMainProcess(projectId),
  })
}

export const useMilestones = () => {
  const { currentProject: projectId } = useContext(ProjectContext).state

  return useQuery({
    queryKey: ['milestones', projectId],
    queryFn: () => OrgService.getProjectMilestones(projectId),
  })
}
