import { FC, useState } from 'react'

interface OrgInputProps {
  onChange: (org?: string) => void
}

const obj = {
  canvas: null as HTMLCanvasElement | null,
}

const getTextWidth = (text: string, font: string) => {
  const canvas = obj.canvas || (obj.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }
  return 10
}

const OrgInput: FC<OrgInputProps> = ({ onChange: _onChange }) => {
  const [left, setLeft] = useState(140)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length === 0) {
      setLeft(140)
      _onChange(undefined)
    } else {
      const width = getTextWidth(value, 'arial 1rem')
      setLeft(Math.max(20, width * 2 + 10))
      _onChange(value)
    }
  }

  return (
    <div>
      <div
        style={{ font: 'arial' }}
        className="inline-block relative input text-base focus-within:outline-blue-700 focus-within:outline focus-within:outline-1rounded-md px-4 py-2"
      >
        <input
          type="text"
          onChange={onChange}
          placeholder="your-organization"
          style={{ font: 'inherit' }}
          className="appearance-none focus:outline-none bg-transparent"
        />

        <span
          style={{ font: 'inherit', left: `${left}px` }}
          className="text-black absolute pl-2"
        >
          .bygg.io
        </span>
      </div>
      <span className="status-text">
        You can get your organization code from the address bar
      </span>
    </div>
  )
}

export default OrgInput
