import { Modal } from 'antd'
import { useContext, useMemo, useState } from 'react'
import { ProjectContext } from 'src/context/project/ProjectContext'
import { ITimelineData, TaskCtrlAppCard } from 'src/interface/org'
import TableKeeperService from 'src/service/TableKeeperService'
import { YearWeek } from 'src/util/time'
import dayjs from 'dayjs'

interface Props {
  timeline: ITimelineData
  appCards: TaskCtrlAppCard[]
}

const CreateTimelineTemplate = ({ timeline, appCards }: Props) => {
  const { currentProject } = useContext(ProjectContext).state
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const inWorkingWeeks = (startWeek: YearWeek, date: string): boolean => {
    const deadline = dayjs(date)
    const endWeek = new YearWeek(deadline)
    return startWeek.isBeforeOrEqual(endWeek)
  }

  const rawTemplate = useMemo(() => {
    return {
      id: crypto.randomUUID(),
      name,
      template: timeline.templateData,
      cards: appCards
        .filter(
          (card) =>
            card.deadline &&
            inWorkingWeeks(YearWeek.clone(timeline.startWeek), card.deadline),
        )
        .map(({ id, ...rest }) => ({ ...rest, oldId: id })),
      createdAt: new Date().toISOString(),
      startWeek: timeline.startWeek,
    }
  }, [timeline, appCards, name])

  const saveTemplate = async () => {
    setLoading(true)
    await TableKeeperService.saveTemplate(currentProject, rawTemplate)
    setLoading(false)
    setIsModalOpen(false)
  }

  return (
    <>
      <button onClick={showModal} className="button button-secondary w-1/2">
        Create template
      </button>
      <Modal
        title="Save Timeline as Template"
        open={isModalOpen}
        centered
        onOk={saveTemplate}
        onCancel={handleCancel}
        confirmLoading={loading}
        okButtonProps={{
          className: '!bg-[#1677ff] hover:scale-105',
        }}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="p-small">Template Name</div>
            <input
              onChange={(e) => setName(e.target.value)}
              className="input"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreateTimelineTemplate
