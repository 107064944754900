import { useEffect, useState } from 'react'
import UserService from '../../service/UserService'
import Spinner from '../../components/input/Spinner'
import { getDomain } from '../../util/const/util'

const Authorize = () => {
  const [boardId, setBoraId] = useState<string | null>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    if (token === null) return
    const boardId = urlParams.get('boardId')
    setBoraId(boardId)
    const data = JSON.parse(atob(token))
    data.host = getDomain(data.host)
    UserService.setUser(data).then(() => {
      setLoading(false)
      window.history.replaceState({}, document.title, '/')
    })
  }, [])

  return (
    <div className="flex h-screen w-full justify-center items-center">
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col gap-4">
          <img
            className="w-48"
            src="https://pi-cdn.atl-paas.net/celebration.svg"
            alt="Success!"
          />
          <div>
            <h1>Success!</h1>
            <p>Your TaskCtrl account was connected with Miro.</p>
          </div>
          <p>
            <a
              href={`https://miro.com/app/board/${boardId ?? ''}`}
              className="button  sticky bottom-0 button-primary"
            >
              Back to Miro
            </a>
          </p>
        </div>
      )}
    </div>
  )
}

export default Authorize
