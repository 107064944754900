import { AppCard } from '@mirohq/websdk-types'
import { Badge, Button, List } from 'antd'
import { useState } from 'react'
import { TaskCtrlAppCard } from 'src/interface/org'

type Props = {
  cards: TaskCtrlAppCard[]
  updated?: boolean
}
const ItemsForSync = ({ cards, updated = false }: Props) => {
  const [showCards, setShowCards] = useState(false)

  const scrollToItem = async (cardId: string) => {
    const card = await miro.board.getById(cardId)
    await miro.board.viewport.zoomTo(card as AppCard)
  }

  const prefix: string = updated ? 'Updated' : 'New'
  return (
    <div className="w-full py-1">
      {cards.length > 0 && (
        <Button
          className={'w-full'}
          type="default"
          onClick={() => setShowCards(!showCards)}
        >
          <div className={'justify-between flex'}>
            {prefix} TaskCtrl items ready for sync
            <Badge
              color={'blue'}
              className={'pl-2'}
              count={cards.length}
            ></Badge>
          </div>
        </Button>
      )}
      {showCards && (
        <List
          dataSource={cards}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta title={item.title} />
              <Button onClick={() => scrollToItem(item.id)}>Go to</Button>
            </List.Item>
          )}
        />
      )}
    </div>
  )
}

export default ItemsForSync
