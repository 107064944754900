import { useContext } from 'react'
import Spinner from '../../components/input/Spinner'
import { UserContext } from '../../context/user/UserContext'
import { RouterProvider } from 'react-router-dom'
import router from '../../routes'
import ProjectContextProvider from '../../context/project/ProjectContextProvider'
import { Alert } from 'antd'
import { useAuth } from 'src/hooks/useAuth'

const RootPage = () => {
  const { loading, user } = useContext(UserContext).state
  const { reset } = useAuth()

  return (
    <>
      {loading ? (
        <Spinner centerScreen />
      ) : user ? (
        <ProjectContextProvider>
          <RouterProvider router={router} />
        </ProjectContextProvider>
      ) : (
        <div>
          <Alert type="warning" message="Can't connect to TaskCtrl" />
          <div className="w-full h-[50vh] flex justify-center items-center">
            <button className="button button-primary" onClick={reset}>
              Try again
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default RootPage
