import type { Optional } from 'utility-types'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import {
  ICreateTimelineData,
  ITimelineCell,
  ITimelineData,
} from '../interface/org'
import { YearWeek } from 'src/util/time'
import { createTimelineFrame } from 'src/miro'
import OrgService from '../service/OrgService'
dayjs.extend(weekOfYear)

const { board } = window.miro
export const WEEK_WIDTH = 1000
export const ROW_HEIGHT = 700
export const PADDING = 400

export const CARD_WIDTH = 460
export const CARD_HEIGHT = 100

export const numberOfWeeks = (start: YearWeek, end: YearWeek) => {
  const weeks: YearWeek[] = []

  for (let current = start; !current.equals(end); ) {
    weeks.push(current)
    const nextFriday = current.getIsoFriday()
    current = new YearWeek(dayjs(nextFriday).add(7, 'day'))
  }

  weeks.push(end)

  return weeks
}

const createTimeline = async (
  data: ICreateTimelineData,
  projectId: number,
  offset: { x: number; y: number } = { x: 0, y: 0 },
  existingOffset: { x: number; y: number } = { x: 0, y: 0 },
) => {
  const { timeline, templateData } = data
  const { planningDate, rows, pastWeeks = 0 } = timeline
  const _weekNumber = planningDate.map((w) => dayjs(w))
  const startWeek = new YearWeek(_weekNumber[0])
  const endWeek = new YearWeek(_weekNumber[1])
  const start = new YearWeek(_weekNumber[0].subtract(pastWeeks, 'week'))
  const weekNumbers = numberOfWeeks(start, endWeek)

  const cells: ITimelineCell[] = []
  weekNumbers.forEach((weekNumber, weekIndex) => {
    rows.forEach((row, rowIndex) => {
      cells.push({
        column: weekIndex,
        weekNumber,
        row: rowIndex,
        data: row,
        endOfWeek: weekNumber.getIsoFriday(),
      })
    })
  })

  const timelineData: Optional<ITimelineData, 'frameId'> = {
    numberOfWeeks: weekNumbers.length,
    numberOfDisciplines: rows.length,
    padding: PADDING,
    cells,
    projectId,
    startWeek,
    endWeek,
    templateData,
    pastWeeks,
  }

  const { timelineItems, frame } = await createTimelineFrame({
    timeline: timelineData,
    rows,
    frameStartWeek: start,
    offset,
    existingOffset,
  })

  const dFreezeDuration = await OrgService.getProjectPlanFreezePeriod(
    projectId,
    'delivery',
  )
  const kpFreezeDuration = await OrgService.getProjectPlanFreezePeriod(
    projectId,
    'key_point',
  )

  const timelineDataComplete = {
    ...timelineData,
    id: frame.id,
    frameId: frame.id,
    deliveryFreezeDuration: dFreezeDuration.plan_freeze_period,
    keyPointFreezeDuration: kpFreezeDuration.plan_freeze_period,
  }

  await board.setAppData('timelineItems', [
    frame.id,
    ...timelineItems.map((item) => item.id),
  ])
  return timelineDataComplete
}

export default createTimeline
