import TimeLinePage from '../timeline/TimelinePage'
import { ProjectContext } from '../../context/project/ProjectContext'
import { UserContext } from '../../context/user/UserContext'
import { useCallback, useContext, useState } from 'react'
import useBroadcastChanel from '../../hooks/useChannel'
import createTimeline from '../../util/createTimeline'
import { getUnsyncedCards } from '../../util/pullTaskCtrlItems'
import defaultUserProfile from 'src/assets/user_profile.png'
import { useSessionId } from 'src/hooks/useUserId'
import { Popover } from 'antd'
import Logout from 'src/components/common/Logout'
import ProjectSelect from 'src/components/input/ProjectSelect'
import {
  ICreateTimelineData,
  ICreateTimelineDataFromTemplate,
  ITimelineData,
  TemplateCard,
} from 'src/interface/org'
import { useBoard } from 'src/hooks/useBoard'
import Spinner from 'src/components/input/Spinner'
import dayjs from 'dayjs'
import {
  useAppCards,
  useCreateAppCard,
  useUpdateTimeline,
} from 'src/query/tableKeeper'
import { clearBoard } from '../../miro'
import { isSynced } from '../../models/TaskCtrlItem'
import { getTimelineFrame } from '../../query/miro'

const SelectionRoot = () => {
  const [loading, setLoading] = useState(false)
  const { currentProject } = useContext(ProjectContext).state
  const { user } = useContext(UserContext).state
  const sessionId = useSessionId()

  const { data: appCards = [] } = useAppCards()
  const { reloadTimeline } = useBoard()
  const createNewCard = useCreateAppCard()
  const updateTimeline = useUpdateTimeline()

  const createNonSyncedCards = useCallback(
    async (
      itemWithNewDeadline: TemplateCard[],
      createdTimeline: ITimelineData,
    ) => {
      const timelineFrame = await getTimelineFrame(createdTimeline.frameId)
      if (!timelineFrame) return
      const nonSyncedCards = await getUnsyncedCards(
        timelineFrame,
        itemWithNewDeadline,
        createdTimeline,
      )
      if (!nonSyncedCards) throw new Error('Error creating non-synced cards')
      const promises = nonSyncedCards.map((item) =>
        createNewCard.mutateAsync({
          timelineFrame,
          item,
          options: { pos: item.pos },
        }),
      )
      await Promise.all(promises)
    },
    [createNewCard],
  )

  const saveTimeline = useCallback(
    async (data: ICreateTimelineData) => {
      setLoading(true)
      const createdTimeline = await createTimeline(data, currentProject)
      return await updateTimeline.mutateAsync({
        timeline: createdTimeline,
        data,
      })
    },
    [currentProject, updateTimeline],
  )

  const editTimeline = async (data: ICreateTimelineData) => {
    const unsyncedCards = appCards.filter((appCard) => !isSynced(appCard))
    await clearBoard()
    const updatedTimeline = await saveTimeline(data)
    if (!updatedTimeline) throw new Error('Error Updating Timeline')
    await createNonSyncedCards(unsyncedCards, updatedTimeline.timeline)
  }

  const saveFromTemplate = useCallback(
    async (data: ICreateTimelineDataFromTemplate) => {
      const createdTimeline = await saveTimeline(data)
      if (!createdTimeline)
        throw new Error('Error Creating Timeline From Template')
      const itemWithNewDeadline: TemplateCard[] = data.appCards.map((c) => {
        return {
          ...c,
          deadline: dayjs(c.deadline).add(data.offSet, 'weeks').format(),
        }
      })
      await createNonSyncedCards(itemWithNewDeadline, createdTimeline.timeline)
    },
    [saveTimeline, createNonSyncedCards],
  )

  useBroadcastChanel<ICreateTimelineData>('frame:create', async (data) => {
    if (data.sessionId !== sessionId) return
    setLoading(true)
    await saveTimeline(data)
    await reloadTimeline()
    setLoading(false)
  })

  useBroadcastChanel<ICreateTimelineData>('frame:update', async (data) => {
    if (data.sessionId !== sessionId) return
    setLoading(true)
    await editTimeline(data)
    await reloadTimeline()
    setLoading(false)
  })

  useBroadcastChanel<ICreateTimelineDataFromTemplate>(
    'frame:create:template',
    async (data) => {
      if (data.sessionId !== sessionId) return
      setLoading(true)
      await saveFromTemplate(data)
      await reloadTimeline()
      setLoading(false)
    },
  )

  if (loading) {
    return <Spinner centerScreen />
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex w-full justify-between items-center">
        <div className="w-2/3">
          <ProjectSelect />
        </div>
        <Popover trigger={'click'} placement="top" content={Logout}>
          <div className="flex h-10 w-10 cursor-pointer hover:scale-105 rounded-full overflow-hidden border-2 border-blue-500">
            <img
              src={user?.photoUrl || defaultUserProfile}
              width={'100%'}
              height={'100%'}
              alt="profile_image"
            />
          </div>
        </Popover>
      </div>
      <hr />
      <TimeLinePage />
    </div>
  )
}

export default SelectionRoot
