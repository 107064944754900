import axios from 'axios'
import { IAppUser } from '../interface/org'

class UserService {
  private keeperUrl = (domain: string) => {
    return (
      import.meta.env.VITE_KEEPER_API ??
      `https://tablekeeper-${domain}.bygg.io/api/`
    )
  }

  public setUser = (user: IAppUser) => {
    const { host, miroUserId, keeperUrl } = user
    const url = `${keeperUrl}miro/domain/${host}/user/${miroUserId}`
    return axios.post(url, user)
  }

  public getUser = async (host: string, miroUserId: string) => {
    const url = `${this.keeperUrl(host)}miro/domain/${host}/user/${miroUserId}`
    try {
      const { data } = await axios.get<IAppUser>(url)
      return data
    } catch (error) {
      return undefined
    }
  }
}

export default new UserService()
