import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import TableKeeperService from 'src/service/TableKeeperService'
import { useMiroAppCard } from './miro'
import {
  ICreateTimelineData,
  ITimelineData,
  TaskCtrlAppCard,
} from 'src/interface/org'
import { createNewMiroCard, updateMiroAppCard } from 'src/miro'
import { useContext } from 'react'
import { ProjectContext } from 'src/context/project/ProjectContext'
import { Frame } from '@mirohq/websdk-types'
import { isSynced } from '../models/TaskCtrlItem'
import pullTaskCtrlItems from '../util/pullTaskCtrlItems'

// TODO this should be fixed
export const useAppCard = (id: string, x?: number, y?: number) => {
  const { data: appCards } = useAppCards()

  return useQuery({
    queryKey: ['appCards', id, x, y],
    queryFn: async () => {
      const card = (appCards ?? []).find((card) => card.id === id)
      if (!card) throw new Error('Not found')
      return card
    },
    enabled: !!appCards,
  })
}

export const useAppCards = () => {
  return useQuery({
    queryKey: ['appCards'],
    queryFn: () => TableKeeperService.getAppCards(),
  })
}

export const useUnsyncedKeypoints = () => {
  const cards = useAppCards()

  return (cards.data ?? []).filter(
    (c) => c.keypoint == undefined && !isSynced(c),
  )
}

export const useTimeline = () => {
  return useQuery({
    queryKey: ['timeline'],
    queryFn: () => TableKeeperService.getTimeline(),
  })
}

export const useUpdateTimeline = () => {
  const queryClient = useQueryClient()
  const { currentProject } = useContext(ProjectContext).state

  return useMutation({
    mutationFn: async ({
      timeline,
      data,
    }: {
      timeline: ITimelineData
      data: ICreateTimelineData
    }) => {
      await TableKeeperService.saveTimeline(timeline)
      return { timeline, data }
    },
    onSuccess: async ({ timeline, data }) => {
      await queryClient.invalidateQueries({ queryKey: ['timeline'] })
      await pullTaskCtrlItems(currentProject, timeline, data)
      return timeline
    },
    onError: (error) => console.error(error),
  })
}

export const useUpdateAppCard = (cardId: string) => {
  const queryClient = useQueryClient()
  const miroAppCard = useMiroAppCard(cardId)

  return useMutation({
    mutationFn: async (item: TaskCtrlAppCard) => {
      await TableKeeperService.updateAppCard(item)
      return item
    },
    onSuccess: (_, item) => {
      queryClient.invalidateQueries({ queryKey: ['appCards'] })
      if (miroAppCard.data) {
        updateMiroAppCard(miroAppCard.data, item, undefined)
      }
    },
    onError: (error) => {
      console.error(error)
    },
  })
}

type CreateProps = {
  pos?: { x?: number; y?: number }
}

export const useCreateAppCard = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      timelineFrame,
      item,
      options = {},
    }: {
      timelineFrame: Frame
      item: Parameters<typeof createNewMiroCard>[0]
      options?: CreateProps
    }) => {
      const miroCard = await createNewMiroCard(item, timelineFrame, {
        pos: options.pos,
      })
      const card: TaskCtrlAppCard = {
        ...item,
        id: miroCard.id,
        createdBy: miroCard.createdBy,
        position: { x: miroCard.x, y: miroCard.y },
      }
      await TableKeeperService.saveAppCard(card)

      return { miroCard, card }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['appCards'] })
    },
    onError: (error) => {
      console.error(error)
    },
  })
}

export const useTemplates = () => {
  const { currentProject } = useContext(ProjectContext).state
  return useQuery({
    queryKey: ['templates'],
    queryFn: () => TableKeeperService.getTemplates(currentProject),
  })
}
