import { FC, useEffect } from 'react'
import { IDiscipline, ISingleUser } from '../../interface/org'
import Select from '../input/Select'
import { useDiscipline, useDisciplineUsers } from 'src/query/taskCtrl'

interface DisciplineResponsibleProps {
  discipline: Pick<IDiscipline, 'id' | 'name' | 'color'>
  onChange: (item: ISingleUser) => void
  disabled?: boolean
  selectedResponsible?: number
}

const DisciplineResponsible: FC<DisciplineResponsibleProps> = ({
  discipline,
  onChange,
  disabled,
  selectedResponsible,
}) => {
  const { data: disciplineUsers } = useDisciplineUsers({
    discipline: discipline.id,
  })

  const { data: disciplineData } = useDiscipline(discipline.id)

  useEffect(() => {
    if (
      selectedResponsible === undefined &&
      disciplineUsers &&
      disciplineUsers.length > 0
    ) {
      onChange(disciplineUsers[0])
    }
  }, [onChange, disciplineUsers, selectedResponsible])

  return (
    <div className="flex gap-3">
      <div className="flex gap-2">
        <div className="app-card">
          <h1 className="app-card--title">{discipline.name}</h1>
          <div className="flex justify-between">
            <p className="app-card--description p-medium">
              {disciplineData?.name}
            </p>
            <svg
              className="app-card--app-logo"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle r="12" cx="12" cy="12" fill={discipline.color} />
            </svg>
          </div>
        </div>
      </div>
      <div className="w-1/2">
        <Select
          onChange={(v) => {
            const user = disciplineUsers?.find((u) => u.id === v)
            user && onChange(user)
          }}
          name={['firstName', 'lastName']}
          value={selectedResponsible}
          label={'Responsible'}
          items={disciplineUsers ?? []}
          id={'id'}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default DisciplineResponsible
