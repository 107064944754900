export function classNames(...args: string[]): string {
  const classes = []

  for (let i = 0; i < args.length; i++) {
    const arg = args[i]
    if (!arg) {
      continue
    }

    const argType = typeof arg

    if (argType === 'string' || argType === 'number') {
      // @ts-expect-error this might fail
      classes.push((this && this[arg]) || arg)
    } else if (Array.isArray(arg)) {
      // @ts-expect-error this might fail
      classes.push(classNames.apply(this, arg))
    }
  }
  return classes.join(' ')
}

function isSafeNumber(str: string) {
  return /^\d+$/.test(str) && BigInt(str) <= Number.MAX_SAFE_INTEGER
}

export const uniqueIds = (ids: number[]): number[] => {
  const set = new Set(ids)
  return Array.from(set)
}

export const extractBigNumber = (str: string): number | string => {
  const found = str.match(/\((-?\d+)\)/)
  const f = found ? found[1] : str
  if (isSafeNumber(f)) {
    return parseInt(f)
  } else return f
}

export function extractNumber(str: string): number | undefined
export function extractNumber(str: string, returnUndef: false): number
export function extractNumber(str: string, returnUndef = true) {
  const found = str.match(/\((-?\d+)\)/)
  if (found) {
    return parseInt(found[1])
  } else if (returnUndef) return
  else return -1
}

export const getDomain = (url: string) => {
  const domain = url.split('.')[0]
  return domain ?? 'localhost'
}

export const taskCtrlLink = (path: string) => {
  const domain = localStorage.getItem('domain')
  if (!domain) return ''

  const byggUrl =
    domain === 'localhost:3000' && import.meta.env.DEV
      ? 'http://localhost:3000'
      : `https://${domain}.bygg.io`
  const url = new URL(path, byggUrl)

  return url.href
}
export const parseDateString = (dateString: string): Date | null => {
  const [day, month, year] = dateString.split('.').map(Number)
  return !isNaN(year) && !isNaN(month) && !isNaN(day)
    ? new Date(year, month - 1, day)
    : null
}
