import { ITimelineData, TaskCtrlAppCard } from '../interface/org'
import dayjs from 'dayjs'
import { AppCard } from '@mirohq/websdk-types'
import { ROW_HEIGHT, WEEK_WIDTH } from 'src/util/createTimeline'
import TableKeeperService, {
  miroToTaskCtrlCard,
} from 'src/service/TableKeeperService'
import { updateMiroAppCard } from 'src/miro'
import { omit } from 'lodash'
import {
  isInPlanningPeriod,
  itemSyncErrors,
  onKeyPointUpdate,
  shouldUpdateDeadline,
  syncedWithTaskCtrlVersion,
} from 'src/util/updateCard'

export const itemReadyForSync = (
  card: TaskCtrlAppCard,
  timeline?: ITimelineData,
) => {
  return itemSyncErrors(card, timeline).length === 0
}

export const isSynced = (card: TaskCtrlAppCard) => {
  return card.synced
}

export const isDelivery = (card: TaskCtrlAppCard) => {
  return card.type === 'delivery'
}
export const updateCardFromMiro = async (
  card: AppCard,
  taskCtrlCard: TaskCtrlAppCard | undefined,
  timeline: ITimelineData,
  copied?: boolean,
) => {
  let shouldSync = false

  const column = Math.floor((card.x - timeline.padding) / WEEK_WIDTH)
  const row = Math.floor((card.y - timeline.padding) / ROW_HEIGHT)
  const data = timeline.cells.find(
    (cell) => cell.column === column && cell.row === row,
  )

  // Create new card if there is none already
  const existingCard =
    copied || !taskCtrlCard
      ? {
          ...miroToTaskCtrlCard(card),
          ...omit(taskCtrlCard, ['id', 'taskCtrlId']),
        }
      : taskCtrlCard

  const cardUpdates: Partial<TaskCtrlAppCard> = {}

  const existsInTaskCtrl =
    existingCard.taskCtrlId && existingCard.taskCtrlId >= 0
  const syncedWithTaskCtrl = existsInTaskCtrl && taskCtrlCard?.synced

  // Can not move a synced card positioned in the back period
  if (
    syncedWithTaskCtrl &&
    taskCtrlCard?.deadline &&
    !isInPlanningPeriod(dayjs(taskCtrlCard.deadline), timeline)
  )
    return

  if (
    !existingCard.responsible?.id ||
    existingCard.discipline?.id !== data?.data.disciplines.id
  ) {
    cardUpdates.responsible = data?.data.responsible
      ? {
          id: data.data.responsible.id,
          label: data.data.responsible.name,
        }
      : undefined
    shouldSync = true
  }
  if (data?.data.disciplines.id !== existingCard.discipline?.id) {
    cardUpdates.discipline = data
      ? {
          id: data.data.disciplines.id,
          label: data.data.disciplines.name,
          color: data.data.disciplines.color,
        }
      : undefined
    shouldSync = true
  }

  if (shouldUpdateDeadline(existingCard, syncedWithTaskCtrl, data)) {
    cardUpdates.deadline = data?.endOfWeek
    shouldSync = true
  }

  if (copied || !taskCtrlCard) {
    card.status = 'connected'
    shouldSync = true
  }

  cardUpdates.position = { x: card.x, y: card.y }

  const updatedCard = { ...existingCard, ...cardUpdates }

  updatedCard.synced = syncedWithTaskCtrlVersion(
    updatedCard,
    existingCard.taskCtrlVersion,
  )

  if (shouldSync) {
    await Promise.all([
      TableKeeperService.saveAppCard(updatedCard),
      updateMiroAppCard(card, updatedCard, timeline),
      (copied || !taskCtrlCard) && card.setMetadata('id', card.id),
    ])

    if (taskCtrlCard?.type == 'keypoint') {
      await onKeyPointUpdate(updatedCard)
    }
  }
}
