import { useCallback, useContext, useRef, useState } from 'react'
import TableKeeperService from 'src/service/TableKeeperService'
import { AppCard, Connector } from '@mirohq/websdk-types'
import { Spin } from 'antd'
import { ProjectContext } from 'src/context/project/ProjectContext'
import {
  BroadcastEvent,
  ICreateDelivery,
  ICreateKeyPoint,
  IUpdateDelivery,
  IUpdateKeyPoint,
  TaskCtrlAppCard,
} from 'src/interface/org'
import { useUserId } from 'src/hooks/useUserId'
import OrgService from 'src/service/OrgService'
import logo from 'src/assets/logo.png'
import miroLogo from 'src/assets/miro.png'
import { partition } from 'lodash'
import { UUID } from 'crypto'
import { useUnsyncedKeypoints } from 'src/query/tableKeeper'
import { updateMiroAppCard } from 'src/miro'
import { isSynced } from 'src/models/TaskCtrlItem'
import * as Sentry from '@sentry/react'
import { broadcastEvent } from '../../index'
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons'

enum SyncStatus {
  PULLING_DATA,
  PREPARING,
  PUSHING_KEYPOINT,
  PUSHING_DATA,
  GETTING_DEPENDENCIES,
  PUSHING_DEPENDENCIES,
  DONE,
}

const isDelivery = (card: TaskCtrlAppCard) => {
  return card.type === 'delivery'
}

const partitionCards = (cards: TaskCtrlAppCard[]) =>
  partition(cards, (card) => isDelivery(card))

/*
 * Returns a map containing all outgoing dependencies based on the given miro cards and
 * connectors, mapped to taskCtrl IDs.
 */
const getDependencies = (
  appCards: TaskCtrlAppCard[],
  connections: Connector[],
) => {
  if (connections.length === 0) return {}

  const id2TaskCtrlIdMapper = new Map<string, number>()

  appCards.forEach((card) => {
    if (!card.taskCtrlId) {
      Sentry.captureMessage(
        'Could not find taskCtrlId of dependency while syncing',
        'error',
      )
      return
    }
    id2TaskCtrlIdMapper.set(card.id, card.taskCtrlId)
  })

  const dependencies: Record<number, number[]> = {}

  id2TaskCtrlIdMapper.forEach((taskCtrlId, cardId) => {
    const deps = connections
      .filter((connection) => connection.start?.item === cardId)
      .map((c) => c.end?.item)
      .filter((item): item is string => item != undefined)

    dependencies[taskCtrlId] = deps
      .map((id) => id2TaskCtrlIdMapper.get(id))
      .filter((item): item is number => item !== undefined)
  })

  return dependencies
}

export const toTaskCtrlKeypoint = (
  card: TaskCtrlAppCard,
  idempotency: UUID,
  userId: number,
  newItem: boolean,
) => {
  const keyPoint = {
    name: card.title,
    description: card.description,
    mile_stone_id: card.mile_stone?.id ?? null,
    endTime: card.deadline,
    user_id: card.user?.id ?? userId,
    responsible_id: card.responsible?.id ?? null,
    discipline_id: card.discipline?.id,
    main_process_id: card.mainProcess?.id,
    idempotency,
  }

  if (newItem) return keyPoint as ICreateKeyPoint

  return {
    ...keyPoint,
    id: card.taskCtrlId,
  } as IUpdateKeyPoint
}
export const toTaskCtrlDelivery = (
  card: TaskCtrlAppCard,
  idempotency: UUID,
  userId: number,
  newItem: boolean,
) => {
  const delivery = {
    name: card.title,
    description: card.description,
    key_point_id: card.keypoint?.id,
    endTime: card.deadline,
    user_id: card.user?.id ?? userId,
    responsible_id: card.responsible?.id ?? null,
    main_process_id: card.mainProcess?.id,
    discipline_id: card.discipline?.id,
    idempotency,
  }

  if (newItem) return delivery as ICreateDelivery
  return {
    ...delivery,
    id: card.taskCtrlId,
  } as IUpdateDelivery
}

const SyncPage = () => {
  const [syncStatus, setStatus] = useState(SyncStatus.PULLING_DATA)
  const { currentProject } = useContext(ProjectContext).state
  const projectId = currentProject
  const keypoints = useUnsyncedKeypoints()
  const [syncStart, setSyncStart] = useState(false)
  const [updateItemsError, setUpdateItemsError] = useState<boolean>(false)

  const keypointRef = useRef<Record<string, number>>({})
  const userId = useUserId()

  const prepareKeypoint = useCallback(() => {
    const mapper = keypointRef.current
    keypoints.forEach((keypoint) => {
      if (!keypoint.taskCtrlId) return
      mapper[keypoint.id] = keypoint.taskCtrlId
    })
    keypointRef.current = mapper
  }, [keypoints])

  const updateMiroAppCards = (
    appCards: TaskCtrlAppCard[],
    idemIdMap: Map<string, UUID>,
    idempotency: { [key: UUID]: number },
    miroCards: AppCard[],
    newItems: boolean = true,
  ) => {
    return appCards
      .map((appCard) => {
        const idemKey = idemIdMap.get(appCard.id)
        const miroCard = miroCards.find((c) => c.id === appCard.id)
        if (!idemKey || !miroCard) {
          Sentry.captureMessage(
            `Could not find appCard matching idempotency after creating ${appCard.type}`,
            'error',
          )
          return
        }
        const newVersion: TaskCtrlAppCard = {
          ...appCard,
          taskCtrlId: newItems ? idempotency[idemKey] : appCard.taskCtrlId,
          synced: true,
        }
        const updatedCard: TaskCtrlAppCard = {
          ...newVersion,
          taskCtrlVersion: newVersion,
        }

        updateMiroAppCard(miroCard, updatedCard)
        return updatedCard
      })
      .filter((c): c is TaskCtrlAppCard => !!c)
  }

  const idempotencyError = (type: string, id: string) => {
    console.error(`Could not find idempotency key for ${type} ${id}`)
    Sentry.captureMessage(`Could not find idempotency for ${type}`, 'error')
  }

  const sendKeypoints = useCallback(
    async (
      keypoints: TaskCtrlAppCard[],
      miroCards: AppCard[],
      newKeyPoints: boolean = true,
    ) => {
      if (!userId) return []

      const idemIdMap = new Map(
        keypoints.map((card) => [card.id, crypto.randomUUID()]),
      )

      const prepared = keypoints.reduce(
        (prev, keypoint) => {
          const idempotency = idemIdMap.get(keypoint.id)
          if (!idempotency) {
            idempotencyError('keypoint', keypoint.id)
            return prev
          }

          const toTaskCtrl = toTaskCtrlKeypoint(
            keypoint,
            idempotency,
            userId,
            newKeyPoints,
          )

          prev.push({
            ...toTaskCtrl,
            idempotency,
          })
          return prev
        },
        [] as (IUpdateKeyPoint | ICreateKeyPoint)[],
      )

      const { idempotency } = newKeyPoints
        ? await OrgService.createKeypoints(
            prepared.filter((item): item is ICreateKeyPoint => !('id' in item)),
            currentProject,
          )
        : await OrgService.updateKeypoints(
            prepared.filter((item): item is IUpdateKeyPoint => 'id' in item),
            currentProject,
          )

      return updateMiroAppCards(
        keypoints,
        idemIdMap,
        idempotency,
        miroCards,
        newKeyPoints,
      )
    },
    [currentProject, userId],
  )

  const keypointIdError = (id: string) => {
    console.error(`Could not find TaskCtrlID of new keypoint ${id}`)
    Sentry.captureMessage(
      'Could not find TaskCtrlID of keypoint while syncing delivery',
      'error',
    )
  }

  const sendDeliveries = useCallback(
    async (
      deliveries: TaskCtrlAppCard[],
      keypoints: TaskCtrlAppCard[],
      miroCards: AppCard[],
      newDeliveries: boolean = true,
    ) => {
      if (!userId) return []

      const idemIdMap = new Map(
        deliveries.map((card) => [card.id, crypto.randomUUID()]),
      )

      const prepared = deliveries.reduce(
        (prev, delivery) => {
          if (!delivery.keypoint?.id) {
            Sentry.captureMessage(
              'Found delivery without valid key point',
              'warning',
            )
            return prev
          }
          // The delivery belongs to a KP from the board
          const miro_kp = keypoints.find(
            (k) => Number(k.id) == delivery.keypoint?.id,
          )
          if (miro_kp) {
            if (!miro_kp.taskCtrlId) {
              keypointIdError(miro_kp.id)
              return prev
            }
            delivery.keypoint.id = miro_kp.taskCtrlId
          }

          const idempotency = idemIdMap.get(delivery.id)
          if (!idempotency) {
            idempotencyError('delivery', delivery.id)
            return prev
          }

          const toTaskCtrl = toTaskCtrlDelivery(
            delivery,
            idempotency,
            userId,
            newDeliveries,
          )

          prev.push({
            ...toTaskCtrl,
            idempotency,
          })
          return prev
        },
        [] as (ICreateDelivery | IUpdateDelivery)[],
      )

      const { idempotency } = newDeliveries
        ? await OrgService.createDeliveries(
            prepared.filter((item): item is ICreateDelivery => !('id' in item)),
            currentProject,
          )
        : await OrgService.updateDeliveries(
            prepared.filter((item): item is IUpdateDelivery => 'id' in item),
            currentProject,
          )

      return updateMiroAppCards(
        deliveries,
        idemIdMap,
        idempotency,
        miroCards,
        newDeliveries,
      )
    },
    [currentProject, userId],
  )

  const pullData = useCallback(async () => {
    prepareKeypoint()
    setStatus(SyncStatus.PREPARING)
    const appCards = await TableKeeperService.getAppCards()
    const [newDeliveries, newKeyPoints] = partitionCards(
      appCards.filter((c) => !isSynced(c) && !c.taskCtrlId),
    )

    const [updatedDeliveries, updatedKeyPoints] = partitionCards(
      appCards.filter((c) => !isSynced(c) && c.taskCtrlId),
    )

    const [existingDeliveries, existingKeyPoints] = partitionCards(
      appCards.filter((c) => isSynced(c) && c.taskCtrlId),
    )

    const miroConnections = await miro.board.get({ type: 'connector' })

    const newKeyPointMiroCards = await miro.board.get({
      id: newKeyPoints.map((k) => k.id),
      type: 'app_card',
    })
    const newDeliveryMiroCards = await miro.board.get({
      id: newDeliveries.map((d) => d.id),
      type: 'app_card',
    })

    const updatedDeliveryMiroCards = await miro.board.get({
      id: updatedDeliveries.map((d) => d.id),
      type: 'app_card',
    })

    const updatedKeyPointsMiroCards = await miro.board.get({
      id: updatedKeyPoints.map((d) => d.id),
      type: 'app_card',
    })

    setStatus(SyncStatus.PUSHING_KEYPOINT)

    let updatedKeypointsResult: TaskCtrlAppCard[] = []
    try {
      updatedKeypointsResult = await sendKeypoints(
        updatedKeyPoints,
        updatedKeyPointsMiroCards,
        false,
      )
    } catch (e) {
      console.error('Tried to update non existing key point')
      setUpdateItemsError(true)
    }

    const keypointsResult = await sendKeypoints(
      newKeyPoints,
      newKeyPointMiroCards,
    )

    setStatus(SyncStatus.PUSHING_DATA)

    let updatedDeliveriesResult: TaskCtrlAppCard[] = []
    try {
      updatedDeliveriesResult = await sendDeliveries(
        updatedDeliveries,
        [...keypointsResult, ...existingKeyPoints, ...updatedKeyPoints],
        updatedDeliveryMiroCards,
        false,
      )
    } catch (e) {
      console.error('Tried to update non existing delivery')
      setUpdateItemsError(true)
    }

    const deliveriesResult = await sendDeliveries(
      newDeliveries,
      [...keypointsResult, ...existingKeyPoints, ...updatedKeyPoints],
      newDeliveryMiroCards,
    )

    await TableKeeperService.bulkSaveAppCard([
      ...keypointsResult,
      ...updatedKeypointsResult,
      ...deliveriesResult,
      ...updatedDeliveriesResult,
    ])

    setStatus(SyncStatus.PUSHING_DEPENDENCIES)
    await OrgService.createDeliveryDependencies(
      getDependencies(
        [
          ...deliveriesResult,
          ...updatedDeliveriesResult,
          ...existingDeliveries,
        ],
        miroConnections,
      ),
      projectId,
    )
    await OrgService.createKeypointDependencies(
      getDependencies(
        [...keypointsResult, ...updatedKeypointsResult, ...existingKeyPoints],
        miroConnections,
      ),
      projectId,
    )
    setStatus(SyncStatus.DONE)
    await broadcastEvent(BroadcastEvent.SYNC_COMPLETE)
  }, [prepareKeypoint, sendDeliveries, sendKeypoints, projectId])

  const data = () => {
    switch (syncStatus) {
      case SyncStatus.PULLING_DATA:
        return 'Pulling data from Miro'
      case SyncStatus.PREPARING:
        return 'Preparing data'
      case SyncStatus.PUSHING_DATA:
        return 'Saving data On TaskCtrl'
      case SyncStatus.PUSHING_DEPENDENCIES:
        return 'Creating Delivery Decencies'
      case SyncStatus.PUSHING_KEYPOINT:
        return 'Creating Keypoint Decencies'
      case SyncStatus.DONE:
        return 'Done'
      default:
        return 'Unknown'
    }
  }
  return (
    <div className="flex justify-center items-center w-full h-full">
      {!syncStart && (
        <div className="flex gap-4 flex-col w-full items-center">
          <div className="flex w-full items-center justify-center gap-10 fap-4">
            <img src={miroLogo} className="w-40" alt="taskCtrl-logo" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
            </svg>
            <img src={logo} className="w-40" alt="taskCtrl-logo" />
          </div>
          <p className="h1 text-center font-semibold pb-4">
            Push items to TaskCtrl
          </p>
          <button
            onClick={() => {
              setSyncStart(true)
              pullData()
            }}
            className={'button sticky bottom-0 button-danger'}
          >
            Synchronize
          </button>
        </div>
      )}

      {syncStatus === SyncStatus.DONE ? (
        updateItemsError ? (
          <div className={'flex flex-col gap-4'}>
            <div className={'flex flex-col items-center gap-4'}>
              <ExclamationCircleTwoTone
                style={{ fontSize: 'xxx-large' }}
                twoToneColor={'red'}
              />
              <h3 className={'h3'}>
                Something went wrong when trying to update one or several items.
                Check if item exists in TaskCtrl before trying again.
              </h3>
            </div>
          </div>
        ) : (
          <div className={'flex flex-col items-center gap-5 '}>
            <CheckCircleTwoTone
              style={{ fontSize: 'xxx-large' }}
              twoToneColor={'#43bc4f'}
            />
            <h1 className="h1"> Success </h1>
          </div>
        )
      ) : syncStart ? (
        <Spin tip="Loading" size="large">
          <div className="mt-24">{data()}...</div>
        </Spin>
      ) : (
        <></>
      )}
    </div>
  )
}

export default SyncPage
