import { useQuery } from '@tanstack/react-query'
import { useBoard } from 'src/hooks/useBoard'

export const getTimelineFrame = async (frameId?: string) => {
  const res = await miro.board.get({ type: 'frame', id: frameId })

  return res && res.length > 0 ? res[0] : undefined
}

export const useTimelineFrame = () => {
  const {
    query: { data: timeline },
  } = useBoard()

  return useQuery({
    queryKey: ['miro', 'timeline', timeline?.frameId],
    queryFn: () => getTimelineFrame(timeline?.frameId),
    enabled: !!timeline?.frameId,
  })
}

export const useMiroAppCard = (id: string) => {
  return useQuery({
    queryKey: ['miro', 'appCards'],
    queryFn: async () => {
      const cards = await miro.board.get({ type: 'app_card', id })
      return cards.length > 0 ? cards[0] : undefined
    },
  })
}
