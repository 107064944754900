import { createBrowserRouter } from 'react-router-dom'
import SelectionRoot from '../pages/home/SelectionRootPage'
import SyncPage from '../pages/sync/SyncPage'
import DetailPage from '../pages/delivery/DetailPage'
import CreateTimelineModal from 'src/pages/timeline/CreateTimelineModal'

const router = createBrowserRouter([
  {
    path: '/',
    element: <SelectionRoot />,
  },
  {
    path: '/newTimeline',
    element: <CreateTimelineModal />,
  },
  {
    path: '/editTimeline',
    element: <CreateTimelineModal edit={true} />,
  },
  {
    path: '/app_card/:id',
    element: <DetailPage />,
  },
  {
    path: '/sync',
    element: <SyncPage />,
  },
  {
    path: '*',
    element: <div>404</div>,
  },
])

export default router
