import { Tabs, TabsProps } from 'antd'
import CreateTimelineForm from './CreateTimelineForm'
import { useBoard } from 'src/hooks/useBoard'

const CreateTimelineModal = ({ edit = false }: { edit?: boolean }) => {
  const {
    query: { data: timeline },
  } = useBoard()

  const items: TabsProps['items'] = [
    {
      key: 'new',
      label: 'New Timeline',
      children: <CreateTimelineForm />,
    },
    {
      key: 'template',
      label: 'From Template',
      children: <CreateTimelineForm fromTemplate={true} />,
    },
  ]

  return (
    <div className="flex flex-col gap-4 relative">
      <h1 className="h1">{edit ? 'Edit' : 'Create'} Timeline</h1>
      {edit && timeline ? (
        <CreateTimelineForm timeline={timeline} />
      ) : (
        <Tabs defaultActiveKey="new" items={edit ? [items[0]] : items} />
      )}
    </div>
  )
}

export default CreateTimelineModal
