import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
import DeliveryCardDetailPage from './DeliveryCardDetailPage'
import KeypointDetailPage from '../keypoint/KeypointDetailPage'
import { BroadcastEvent } from '../../interface/org'
import useBroadcastChannel from 'src/hooks/useChannel'
import { useAppCard, useTimeline } from 'src/query/tableKeeper'

const DetailPage = () => {
  const { id } = useParams()
  const channel = useBroadcastChannel('item:update')
  const { data: timeline } = useTimeline()
  const { data: card, isPending } = useAppCard(id ?? 'null')

  const isDelivery = card?.type == 'delivery'

  const onUpdate = () => {
    channel.postMessage({ event: BroadcastEvent.CARD_UPDATE })
  }

  return (
    <>
      {isPending || !card || !timeline ? (
        <>
          <div className="flex justify-center items-center w-full h-full">
            <Spin size="large" />
          </div>
        </>
      ) : isDelivery ? (
        <DeliveryCardDetailPage
          onUpdate={onUpdate}
          card={card}
          timeline={timeline}
        />
      ) : (
        <KeypointDetailPage
          onUpdate={onUpdate}
          card={card}
          timeline={timeline}
        />
      )}
    </>
  )
}

export default DetailPage
