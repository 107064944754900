import { useState, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import CardInfo from './CardInfo'
import useClickOutside from 'src/hooks/useClickOutside'
import { useAppCard } from 'src/query/tableKeeper'
import { AppCard } from '@mirohq/websdk-types'

const ItemInfo = ({ selectedCard }: { selectedCard: AppCard }) => {
  const { id, x, y } = selectedCard
  const { data, refetch } = useAppCard(id, x, y)
  return data ? <CardInfo item={data} reloadInfo={refetch} /> : null
}

const BottomInfo = () => {
  const [selectedCard, setSelectedCard] = useState<AppCard>()
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    miro.board.ui.on('selection:update', async ({ items }) => {
      const cards = items.filter((item) => item.type === 'app_card')
      if (cards.length === 1) {
        setSelectedCard(cards[0] as AppCard)
      } else {
        setSelectedCard(undefined)
      }
    })

    return () => {
      miro.board.ui.off('selection:update', () => {})
    }
  }, [])

  useClickOutside(elementRef, () => setSelectedCard(undefined))

  return (
    <div
      className={twMerge(
        selectedCard && 'bg-[#f9fafb3b]',
        'fixed top-0 left-0 w-full h-full  z-40 transition-opacity duration-300',
        !selectedCard && 'hidden',
      )}
    >
      <div
        className={twMerge(
          selectedCard ? 'translate-y-0' : 'translate-y-full',
          `fixed bottom-0 left-0 w-full bg-white border-t opacity-100 border-gray-300 transform transition-transform duration-300 shadow-lg`,
        )}
      >
        <div ref={elementRef} className="flex flex-col p-4 mb-10 gap-4">
          {selectedCard ? <ItemInfo selectedCard={selectedCard} /> : null}
        </div>
      </div>
    </div>
  )
}

export default BottomInfo
