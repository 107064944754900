import { AppCard } from '@mirohq/websdk-types'
import { Badge, Button, List } from 'antd'
import { useState } from 'react'
import { TaskCtrlAppCard } from 'src/interface/org'

type Error = {
  card: TaskCtrlAppCard
  errors: string[]
}
type Props = {
  errors: Error[]
  syncErrors?: boolean
}
const ItemSyncErrors = ({ errors, syncErrors = false }: Props) => {
  const [showErrors, setShowErrors] = useState(false)

  const scrollToItem = async (cardId: string) => {
    const card = await miro.board.getById(cardId)
    await miro.board.viewport.zoomTo(card as AppCard)
  }

  const headline = syncErrors
    ? 'Errors preventing sync'
    : 'Existing errors on synced items'

  return (
    <div className="w-full">
      {errors.length > 0 && (
        <Button
          className={'w-full'}
          danger
          type={syncErrors ? 'primary' : 'default'}
          onClick={() => setShowErrors(!showErrors)}
        >
          <div className={'justify-between flex'}>
            {headline}
            <Badge
              color={'red-400'}
              className={'border-1 border-red-500 rounded-full'}
              count={errors.length}
            ></Badge>
          </div>
        </Button>
      )}
      {showErrors && (
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item key={item.card.id}>
              <List.Item.Meta
                title={item.card.title}
                description={
                  <span className="text-red-500">{item.errors[0]}</span>
                }
              />
              <Button onClick={() => scrollToItem(item.card.id)}>Go to</Button>
            </List.Item>
          )}
        />
      )}
    </div>
  )
}

export default ItemSyncErrors
