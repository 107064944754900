import {
  SelectionUpdateEvent,
  AppCardOpenEvent,
  AppCardConnectEvent,
  ItemsDeleteEvent,
  AppCard,
  SelectableItems,
} from '@mirohq/websdk-types'
import TableKeeperService from './service/TableKeeperService'
import { BroadcastEvent, ITimelineData, TaskCtrlAppCard } from './interface/org'
import { isSynced, updateCardFromMiro } from './models/TaskCtrlItem'
import 'dayjs/plugin/isoWeek'
import dayjs from 'dayjs'
import { isInPlanningPeriod } from './util/updateCard'
import { queryClient } from './app'

const isAppCard = (item: SelectableItems): item is AppCard =>
  item.type === 'app_card'

export const broadcastEvent = async (event: BroadcastEvent) => {
  // Broad cast channel
  const channel = new BroadcastChannel('item:update')
  channel.postMessage({ event })
}

const handleItemInBackPeriod = async (
  taskCtrlCard: TaskCtrlAppCard,
  card: AppCard,
  timeline: ITimelineData,
) => {
  miro.board.notifications.showError(
    'You can not re-plan items in the past weeks',
  )
  const [timelineFrame] = await miro.board.get({
    type: 'frame',
    id: timeline.frameId,
  })
  if (!taskCtrlCard.position) return
  TableKeeperService.updateAppCardPosition(
    card,
    {
      x: taskCtrlCard.position.x,
      y: taskCtrlCard.position.y,
    },
    timelineFrame,
  )
}

const updateAppCard = async (updateEvent: SelectionUpdateEvent) => {
  const timeline: ITimelineData | undefined = queryClient.getQueryData([
    'timeline',
  ])
  if (!timeline) return
  const appCards = updateEvent.items.filter(isAppCard)
  let copiedFrom: string | undefined
  for (const card of appCards) {
    if (card.status === 'disconnected') {
      // a duplicated or newly created app card
      setTimeout(() => {
        broadcastEvent(BroadcastEvent.CARD_CREATE)
      }, 1000)
      copiedFrom = (await card.getMetadata('id')) as string
    }

    const taskCtrlCard = await TableKeeperService.getAppCard(
      copiedFrom ?? card.id,
    )

    // If the cards is synced and positioned in the back period
    if (
      taskCtrlCard?.id === card.id &&
      isSynced(taskCtrlCard) &&
      !isInPlanningPeriod(dayjs(taskCtrlCard.deadline), timeline)
    ) {
      await handleItemInBackPeriod(taskCtrlCard, card, timeline)
      return
    }

    await updateCardFromMiro(
      card,
      taskCtrlCard,
      timeline,
      card.status === 'disconnected',
    )
    broadcastEvent(BroadcastEvent.CARD_UPDATE)
  }
}

const openAppCard = async (event: AppCardOpenEvent) => {
  const { appCard } = event
  miro.board.ui.openModal({
    url: `/app_card/${appCard.id}`,
  })
}

const iconClick = async () => {
  await miro.board.ui.openPanel({ url: '/' })
}

const appCardConnect = async (e: AppCardConnectEvent) => {
  const timeline: ITimelineData | undefined = queryClient.getQueryData([
    'timeline',
  ])
  if (!timeline) return
  updateCardFromMiro(e.appCard, undefined, timeline)
  await e.appCard.setMetadata('id', e.appCard.id)
  broadcastEvent(BroadcastEvent.CARD_CREATE)
}

const itemDelete = async (e: ItemsDeleteEvent) => {
  const { items } = e
  const promise: Promise<void>[] = []
  for (const item of items) {
    if (item.type === 'app_card') {
      promise.push(TableKeeperService.deleteAppCard(item.id))
      promise.push(broadcastEvent(BroadcastEvent.DELETE_APP_CARD))
    } else if (item.type === 'frame') {
      promise.push(TableKeeperService.deleteTimeline(item.id))
      promise.push(broadcastEvent(BroadcastEvent.FRAME_DELETE))
    }
  }
  await Promise.all(promise)
}

export async function init() {
  const { ui } = miro.board
  ui.on('icon:click', iconClick)
  ui.on('experimental:items:update', updateAppCard)
  ui.on('app_card:open', openAppCard)
  ui.on('app_card:connect', appCardConnect)
  ui.on('items:delete', itemDelete)
}

init()
