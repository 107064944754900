import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { IProject } from '../../interface/org'
import OrgService from '../../service/OrgService'
import { ProjectContext } from './ProjectContext'
import useBroadcastChanel from '../../hooks/useChannel'
import { useUserId } from '../../hooks/useUserId'

const PROJECT_KEY = 'currentProject'

const ProjectContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [projects, setProjects] = useState<IProject[]>([])
  const [loading, setLoading] = useState(true)
  const [readonly, _setReadonly] = useState(false)
  const [currentProject, _setCurrentProject] = useState<number>(() => {
    const project = localStorage.getItem(PROJECT_KEY)
    return project ? parseInt(project) : 1
  })

  const setCurrentProject = (project: number) => {
    _setCurrentProject(project)
    localStorage.setItem(PROJECT_KEY, project.toString())
  }

  const projectChannel = useBroadcastChanel<number>(
    'project:update',
    setCurrentProject,
  )

  useEffect(() => {
    projectChannel.postMessage(currentProject)
  }, [currentProject, projectChannel])

  const userId = useUserId()

  const pullProjects = useCallback(async () => {
    if (!userId) return

    OrgService.getProjects(userId).then((p: IProject[]) => {
      setProjects(p)
      if (!localStorage.getItem(PROJECT_KEY)) {
        setCurrentProject(p[0].id)
      }
      setLoading(false)
    })
  }, [userId])

  useEffect(() => {
    pullProjects()
  }, [pullProjects])

  const setReadonly = (projectId: number) => {
    _setReadonly(true)
    setCurrentProject(projectId)
  }

  const context = {
    actions: {
      pullProjects,
      setCurrentProject,
      setReadonly,
    },
    state: {
      projects,
      loading,
      currentProject,
      readonly,
    },
  }
  return (
    <ProjectContext.Provider value={context}>
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectContextProvider
