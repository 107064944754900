import { Input } from 'antd'
import Select from '../input/Select'
import { FC, useMemo, useState } from 'react'
import { useMainProcesses, useMilestones, useUsers } from 'src/query/taskCtrl'
import { useCreateAppCard } from 'src/query/tableKeeper'
import { AppCard } from '@mirohq/websdk-types'
import { TaskCtrlAppCard } from 'src/interface/org'
import { useTimelineFrame } from 'src/query/miro'
const { TextArea } = Input

interface AddKeypointPageProps {
  onCancel: () => void
  onSave: (card: TaskCtrlAppCard, miroCard: AppCard) => void
}

const AddKeypointPage: FC<AddKeypointPageProps> = ({ onCancel, onSave }) => {
  const [selectedMainProcess, setSelectedMainProcess] = useState<number>()
  const [selectedMilestone, setSelectedMilestone] = useState<number>()
  const [selectedUsers, setSelectedUsers] = useState<number>()
  const [description, setDescription] = useState('')
  const [title, setTitle] = useState<string>()
  const createNewAppCard = useCreateAppCard()
  const { data: timelineFrame } = useTimelineFrame()

  const { data: mainProcesses } = useMainProcesses()
  const { data: users } = useUsers()
  const { data: milestones } = useMilestones()

  const disableButton = useMemo(() => {
    return (
      title === undefined ||
      selectedUsers === undefined ||
      selectedMainProcess === undefined
    )
  }, [selectedMainProcess, title, selectedUsers])

  const createAppCard = async () => {
    const mainProcess = (mainProcesses ?? []).find(
      (k) => k.id === selectedMainProcess,
    )
    const mileStone = (milestones ?? []).find((m) => m.id === selectedMilestone)
    const reporter = (users ?? []).find((u) => u.id === selectedUsers)

    if (!title || !reporter || !mainProcess || !timelineFrame) return

    const item: Parameters<typeof createNewAppCard.mutate>[0] = {
      item: {
        type: 'keypoint',
        title,
        description,
        user: {
          id: reporter.id,
          label: `${reporter.firstName} ${reporter.lastName}`,
        },
        mainProcess: { id: mainProcess.id, label: mainProcess.name },
        mile_stone: mileStone
          ? {
              id: mileStone.id,
              label: `${mileStone.name}`,
              deadline: mileStone.deadline,
            }
          : undefined,
      },
      timelineFrame,
    }
    const res = await createNewAppCard.mutateAsync(item)
    onSave(res.card, res.miroCard)
  }

  const onSubmit = () => {
    if (disableButton) return
    createAppCard()
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="">Title*</div>
        <Input
          size="large"
          placeholder="Add title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="">Description</div>
        <TextArea
          rows={4}
          placeholder="Add description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div>
        <Select
          onChange={setSelectedUsers}
          name={['firstName', 'lastName']}
          value={selectedUsers}
          label={'Reporter*'}
          items={users ?? []}
          id={'id'}
        />
      </div>
      <div>
        <Select
          onChange={setSelectedMilestone}
          name={['name']}
          value={selectedMilestone}
          label={'Milestone'}
          items={milestones ?? []}
          id={'id'}
        />
      </div>
      <div>
        <Select
          onChange={setSelectedMainProcess}
          name={['name']}
          value={selectedMainProcess}
          label={'Main process*'}
          items={mainProcesses ?? []}
          id={'id'}
        />
      </div>

      <div className="flex gap-3 items-center justify-center">
        <button
          onClick={onCancel}
          className={'button sticky !mb-0 button-secondary w-1/2'}
        >
          Cancel
        </button>
        <button
          onClick={onSubmit}
          disabled={disableButton}
          className={'button sticky button-primary w-1/2'}
        >
          Add keypoint
        </button>
      </div>
    </div>
  )
}

export default AddKeypointPage
