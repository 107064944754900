import { useContext } from 'react'
import { ProjectContext } from 'src/context/project/ProjectContext'
import Select from './Select'

const ProjectSelect = () => {
  const { projects, currentProject, readonly } =
    useContext(ProjectContext).state
  const { setCurrentProject } = useContext(ProjectContext).actions
  return (
    <Select
      onChange={setCurrentProject}
      name={['projectNumber', 'projectName']}
      value={currentProject}
      label={'Projects'}
      items={projects}
      disabled={readonly}
      id={'id'}
    />
  )
}

export default ProjectSelect
