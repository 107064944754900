import { useQueryClient } from '@tanstack/react-query'
import { useContext, useEffect } from 'react'
import { ProjectContext } from 'src/context/project/ProjectContext'
import { useAppCards, useTimeline } from 'src/query/tableKeeper'
import TableKeeperService from 'src/service/TableKeeperService'

type UseTimeline = {
  query: ReturnType<typeof useTimeline>
  reloadTimeline: () => Promise<void>
  clearTimeline: () => Promise<void>
  resetTableKeeper: () => Promise<void>
}

export const useBoard = (): UseTimeline => {
  const { setReadonly } = useContext(ProjectContext).actions
  const queryClient = useQueryClient()

  const timelineQuery = useTimeline()
  const { data: appCards = [] } = useAppCards()

  useEffect(() => {
    timelineQuery.data && setReadonly(timelineQuery.data.projectId)
  })

  const resetTableKeeper = async () => {
    if (timelineQuery.data)
      await TableKeeperService.deleteTimeline(timelineQuery.data.frameId)
    const deleteAppCards = appCards.map((card) =>
      TableKeeperService.deleteAppCard(card.id),
    )
    await Promise.all(deleteAppCards)
    window.location.reload()
  }

  const clearTimeline = async () => {
    await resetTableKeeper()
    await queryClient.invalidateQueries({ queryKey: ['appCards'] })
    await queryClient.invalidateQueries({ queryKey: ['timeline'] })
  }

  return {
    query: timelineQuery,
    reloadTimeline: async () => {
      await queryClient.invalidateQueries({ queryKey: ['timeline'] })
      await queryClient.invalidateQueries({ queryKey: ['appCards'] })
    },
    clearTimeline,
    resetTableKeeper,
  }
}
