import { useCallback, useEffect, useState } from 'react'
import UserService from '../service/UserService'
import { getMiroUserInfo } from '../util/info'

export enum AuthState {
  LOADING,
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHORIZE,
  OUTSIDE_MIRO,
}

export const useAuth = () => {
  const [authState, setAuthState] = useState(AuthState.LOADING)

  const checkState = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    const userInfo = localStorage.getItem('userInfo')
    const domain = localStorage.getItem('domain')

    if (token) {
      setAuthState(AuthState.AUTHORIZE)
      return
    }

    const miroUserId = await getMiroUserInfo()

    if (!miroUserId) {
      setAuthState(AuthState.OUTSIDE_MIRO)
      return
    }

    if (userInfo) {
      setAuthState(AuthState.AUTHENTICATED)
      return
    }

    if (!domain) {
      setAuthState(AuthState.UNAUTHENTICATED)
      return
    }

    const fetchedUserInfo = await UserService.getUser(domain, miroUserId.id)

    if (fetchedUserInfo) {
      localStorage.setItem('userInfo', JSON.stringify(fetchedUserInfo))
      setAuthState(AuthState.AUTHENTICATED)
    } else {
      setAuthState(AuthState.UNAUTHENTICATED)
    }
  }, [])

  useEffect(() => {
    checkState()
  }, [checkState])

  const reset = () => {
    localStorage.clear()
    window.location.reload()
  }

  return { authState, reset }
}
