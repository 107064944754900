import { createContext } from 'react'
import { IProject } from '../../interface/org'

export interface IProjectContext {
  actions: {
    pullProjects: () => void
    setCurrentProject: (project: number) => void
    setReadonly: (projectId: number) => void
  }
  state: {
    projects: IProject[]
    loading: boolean
    currentProject: number
    readonly: boolean
  }
}

export const ProjectContext = createContext({} as IProjectContext)
