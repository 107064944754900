import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { ISingleUser, IUserData } from '../../interface/org'
import { UserContext } from './UserContext'
import OrgService from '../../service/OrgService'

const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<ISingleUser>()
  const [notConnected, setNotConnected] = useState<boolean>()
  const [sessionId, setSessionId] = useState('')

  const userDataToSingeUser = (data: IUserData): ISingleUser => {
    return {
      id: data.id,
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      photoUrl: data.image_url,
      userId: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
    }
  }

  const reload = useCallback(async () => {
    setLoading(true)
    const random = () => Math.floor(Math.random() * 1000000).toString()
    try {
      const { user } = await OrgService.getOwnUser()
      setUser(userDataToSingeUser(user))
    } catch (error) {
      console.error(error)
      setNotConnected(true)
    }
    setSessionId(random())
    setLoading(false)
  }, [])

  useEffect(() => {
    reload()
  }, [reload])

  const context = {
    actions: {
      reload,
    },
    state: {
      loading,
      user,
      notConnected,
      sessionId,
    },
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export default UserContextProvider
